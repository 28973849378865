/* eslint-disable prettier/prettier */
import getValuesFromEnumType from "~/utils/getValuesFromEnumType";
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "@tanstack/react-query";
import { unitAPIv2 } from "~/api";
import {
  TIntegrationTypes,
  TIntegrationTypesEnum,
  TConnectionResponse,
} from "~/types/integrations";

// export const useInitIntegrationConnection = (
//   type: TIntegrationTypes,
//   enabled?: boolean
// ): UseQueryResult<{ redirect_url: string }, Error> =>
//   useQuery({
//     enabled: !!enabled,
//     queryFn: unitAPIv2.services?.[type]?.init,
//     queryKey: ["connection/init", type],
//   });

export const useInitIntegrationConnection = (
  type: TIntegrationTypes,
  enabled?: boolean
): UseQueryResult<{ redirect_url: string; }, Error> => {
  const queryKey = ["connection/init", type];

  return useQuery(queryKey, () => unitAPIv2.services?.[type]?.init(), {
    enabled: !!enabled,
    staleTime: 60000, // Adjust the staleTime value as per your requirements
    retry: false,
  });
};

export const useDeleteIntegrationConnection = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ type }: { type: TIntegrationTypes; }) =>
      unitAPIv2.services[type].deleteConnection(),
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries({
        queryKey: ["connection", variables.type]
      }); // Update the query key here
    },
  });
};

export const useGetIntegrationConnection = (
  type: "reddit" | "gmail",
  enabled = true
): UseQueryResult<TConnectionResponse | null, Error> => {
  const queryClient = useQueryClient();
  return useQuery<TConnectionResponse | null, Error>({
    staleTime: 60000,
    enabled: enabled,
    queryKey: ["connection", type],
    queryFn: unitAPIv2.services?.[type]?.connection,
    onError: () => {
      // currently API returns 404 if no connection, so we need to invalidate the query to prevent stale data
      queryClient.setQueryData(["connection", type], null);
    },
  });
};

export const useGetAllIntegrations = (
  disableInit?: boolean
):
  | {
    [key in keyof typeof TIntegrationTypesEnum]:
    | TConnectionResponse
    | { redirect_url: string; };
  }
  | any => {
  const integrationTypes = getValuesFromEnumType(TIntegrationTypesEnum);
  const integrationsMap = {};

  const integrationArr: any = [];

  Object.values(integrationTypes).map(async (source) => {
    // check if integration is linked
    const { data: integrationData, isLoading: isLoadingIntegrationData } =
      useGetIntegrationConnection(source as "gmail") || {};

    // else make init request (returns link to connect)
    const { data: initData, isLoading: isLoadingInit } =
      useInitIntegrationConnection(
        source as "gmail",
        !disableInit && !isLoadingIntegrationData && !integrationData
      ) || {};

    if (!isLoadingIntegrationData && integrationData) {
      integrationArr.push(integrationData);

      Object.assign(integrationsMap, {
        [source]: {
          ...integrationData,
        },
      });
    } else if (!isLoadingInit && initData) {
      integrationArr.push({ type: source, ...initData });

      Object.assign(integrationsMap, {
        [source]: {
          redirect_url: initData.redirect_url,
        },
      });
    }
  });

  return integrationsMap;
};
