export const ANIMATION_DURATION = 200;

const ANIMATIONS = {
  FADE_IN: {
    animationDuration: ANIMATION_DURATION,
    from: {
      opacity: 0,
      position: "absolute",
      width: "100%",
      // filter: "blur(10px)",
    },
    enter: {
      opacity: 1,
      // filter: "blur(0px)",
      width: "100%",
    },
  },
  FADE_IN_FLOW: {
    animationDuration: 800,
    from: {
      opacity: 0,
      //  filter: "blur(1px)",
    },
    enter: {
      opacity: 1,
      // filter: "blur(0px)",
    },
  },
  FADE_IN_PROTECTED: {
    animationDuration: ANIMATION_DURATION,
    from: {
      opacity: 0,
      position: "absolute",
      //filter: "blur(10px)",
      left: "50%",
      transform: "translateX(-50%) scale(0.95)",
    },
    leave: {
      opacity: 0.5,
      //filter: "blur(5px)",
      transition: "opacity 0.3s, filter 0.2s",
    },
    enter: {
      opacity: 1,
      filter: "blur(0px)",
      transform: "translateX(-50%) scale(1)",
      width: "100%",
      transition: "opacity 0.3s, filter 0.3s, transform 0.3s",
    },
  },
  FADE_OUT: {
    animationDuration: ANIMATION_DURATION,
    from: {
      opacity: 1,
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%) scale(1)",
      filter: "blur(0px)",
    },
    enter: {
      opacity: 1,
      filter: "blur(0px)",
      transform: "translateX(-50%) scale(1)",
      transition: "opacity 0.3s, filter 0.3s, transform 0.3s",
    },
  },
  FADE_AND_SCALE: {
    animationDuration: ANIMATION_DURATION,
    from: {
      opacity: 0,
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%) scale(0.05)",
      filter: "blur(30px)",
    },
    leave: {
      opacity: 0,
      transform: "translateX( -50%) scale(0.05)",
      filter: "blur(30px)",
    },
    enter: {
      opacity: 1,
      transform: "translateX(-50%) scale(1)",
      filter: "blur(0px)",
    },
  },
  FADE_AND_BLUR: {
    animationDuration: ANIMATION_DURATION,
    from: {
      opacity: 0,
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%) scale(1)",
      filter: "blur(0px)",
    },
    leave: {
      opacity: 0,
      transform: "translateX( -50%) scale(1)",
      filter: "blur(0px)",
    },
    enter: {
      opacity: 1,
      transform: "translateX(-50%) scale(1)",
      filter: "blur(0px)",
    },
  },
};

export default ANIMATIONS;
