import React, { FC, Fragment } from "react";
import { Amplify } from "aws-amplify";
import awsConfig from "../aws-exports";
import { CssBaseline, ThemeProvider as MuiThemeProvider } from "@mui/material";
import theme from "~/common/mui/theme";
import "../common/assets/fonts/index.css";
import { AuthProvider } from "~/context/AuthContext";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import updateLocale from "dayjs/plugin/updateLocale";
import relativeTime from "dayjs/plugin/relativeTime";
import customParseFormat from "dayjs/plugin/customParseFormat";
import EntryPoint from "~/router/EntryPoint";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "~/common/assets/css/main.css";
import palette from "~/common/mui/theme/palette";
import { SettingsProvider } from "~/context/SettingsContext";
import { MotionConfig } from "framer-motion";
import { REDUCED_MOTION } from "~/common/config";
import paletteUI from "~/common/config/paletteUI";

dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
dayjs.extend(duration);
dayjs.extend(updateLocale);

dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s",
    s: "1m",
    m: "1m",
    mm: "%dm",
    h: "1h",
    hh: "%dh",
    d: "1d",
    dd: "%dd",
    M: "1m",
    MM: "%dm",
    y: "1y",
    yy: "%dy",
  },
});

Amplify.configure(awsConfig);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
      refetchOnReconnect: true,
      // Query error handler
      onError: (e) => {
        console.log(e);
        const message = (e as Error).message;
        toast.error(message);
      },
      cacheTime: Infinity,
    },
  },
});
queryClient.clear();

const App: FC = () => {
  return (
    <Fragment>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <SettingsProvider>
              <MuiThemeProvider theme={theme}>
                <CssBaseline />
                <MotionConfig reducedMotion={REDUCED_MOTION}>
                  <EntryPoint />
                </MotionConfig>
                <ToastContainer
                  position="bottom-right"
                  autoClose={1000}
                  hideProgressBar
                  newestOnTop
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="dark"
                  limit={4}
                  closeButton={false}
                  style={{
                    background: "transparent",
                    backdropFilter: "blur(4px)",
                    marginLeft: 16,
                    borderRadius: 12,
                    maxWidth: 214,
                  }}
                  toastStyle={{
                    minHeight: 60,
                    borderRadius: 12,
                    background: paletteUI.indicators.green[16],
                    backdropFilter: "blur(4px)",
                    boxShadow: "none",
                    border: "1px solid",
                    borderColor: paletteUI.indicators.green.main,
                    marginBottom: 8,

                    color: palette.white[80],
                    fontSize: 14,
                    fontWeight: 500,
                    fontFamily: "Poppins",
                  }}
                />
              </MuiThemeProvider>
            </SettingsProvider>
          </AuthProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </Fragment>
  );
};

export default App;
