import React, { FC } from "react";
import {
  AppBar,
  Box,
  Container,
  Toolbar,
  Typography,
  Link,
} from "@mui/material";

import { ROUTES } from "~/common/config/router/routes";
import Logo from "~/components/shared/Logo";
import palette from "~/common/mui/theme/palette";
import { Link as RouterLink } from "react-router-dom";

type IGuestScreenHeader = {
  props?: any;
};

const GuestScreenHeader: FC<IGuestScreenHeader> = () => {
  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        height: 73,
        borderBottom: "none",
        background: "transparent",
      }}
    >
      <Container maxWidth="appbar">
        <Toolbar disableGutters sx={{ height: 73 }}>
          <Box
            sx={{
              flexGrow: 1,
              gap: 4.2,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Box
              component={RouterLink}
              to={ROUTES.HOME}
              sx={{ textDecoration: "none" }}
            >
              <Logo />
            </Box>
          </Box>

          <Box
            sx={{
              display: "inline-flex",
              gap: [1.5, 4.5],
            }}
          >
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 500,
                fontFamily: "Poppins",
                color: palette.white[80],
              }}
            >
              Features
            </Typography>
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 500,
                fontFamily: "Poppins",
                color: palette.white[80],
              }}
            >
              Support
            </Typography>
            <Link
              sx={{
                fontSize: 14,
                fontWeight: 500,
                fontFamily: "Poppins",
                color: palette.primary.main,
                transition: "color 0.2s ease-in-out",
                "&:hover": {
                  color: palette.white.main,
                },
              }}
              href={ROUTES.LOGIN}
              underline="none"
            >
              Log in
            </Link>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default GuestScreenHeader;
