import { ComponentType, lazy, LazyExoticComponent } from "react";
import { ROUTES } from "~/common/config/router/routes";
import DigestSkeleton from "~/components/Digest/Skeleton";
import ANIMATIONS from "~/common/config/animations";

export type TRoute = {
  path: string;
  redirect?: string;
  exact?: boolean;
  element: LazyExoticComponent<ComponentType<any>>;
  isProtected: boolean;
  children?: TRoute[];
  fallback?: JSX.Element;
  animation?: {
    animationDuration: number;
    from?: Record<string, string | number>;
    enter?: Record<string, string | number>;
    leave?: Record<string, string | number>;
  };
};

const HOME_URL = "/";

const routesConfig: TRoute[] = [
  {
    path: "/",
    isProtected: true,
    element: lazy(() => import("~/pages/Digest")),
    //animation: ANIMATION_FADE_IN,
    animation: ANIMATIONS.FADE_IN,
  },
  {
    path: "/",
    isProtected: false,
    element: lazy(() => import("~/pages/HomeScreen")),
    animation: ANIMATIONS.FADE_IN,
  },
  {
    path: ROUTES.LOGIN,
    isProtected: false,
    redirect: HOME_URL,
    element: lazy(() => import("~/pages/User/Auth/LogIn")),
    animation: ANIMATIONS.FADE_AND_SCALE,
  },
  {
    path: ROUTES.SIGNUP,
    isProtected: false,
    redirect: HOME_URL,
    element: lazy(() => import("~/pages/User/Auth/SignUp")),
    animation: ANIMATIONS.FADE_AND_SCALE,
  },
  {
    path: ROUTES.RESTORE,
    isProtected: false,
    redirect: HOME_URL,
    element: lazy(() => import("~/pages/User/Auth/Restore")),
    animation: ANIMATIONS.FADE_AND_SCALE,
  },
  {
    path: ROUTES.WELCOME,
    isProtected: true,
    element: lazy(() => import("~/pages/Flow/Welcome")),
    animation: ANIMATIONS.FADE_IN_FLOW,
  },
  {
    path: ROUTES.FLOW_SETUP,
    isProtected: true,
    element: lazy(() => import("~/pages/Flow/Setup")),
    animation: ANIMATIONS.FADE_IN_FLOW,
  },
  {
    path: ROUTES.PROFILE,
    isProtected: true,
    element: lazy(() => import("~/pages/User/Profile")),
  },
  //  Sources
  {
    path: ROUTES.DIGEST,
    isProtected: true,
    element: lazy(() => import("~/pages/Digest")),
    fallback: <DigestSkeleton />,
    animation: ANIMATIONS.FADE_IN,
  },
  //  Digest navigation
  {
    path: ROUTES.DIGEST_BY_UUID,
    isProtected: true,
    element: lazy(() => import("~/pages/Digest")),
  },
  {
    path: ROUTES.READER_BY_POST_UUID,
    isProtected: true,
    element: lazy(() => import("~/components/Digest/ReaderView")),
  },

  {
    path: ROUTES.DIGEST_SETUP,
    isProtected: true,
    element: lazy(() => import("~/pages/DigestSetup")),
    animation: ANIMATIONS.FADE_IN,
  },
  {
    path: ROUTES.CALLBACK_URL_TYPE,
    element: lazy(() => import("~/pages/CallbackPage")),
    isProtected: true,
    animation: ANIMATIONS.FADE_IN,
  },
  {
    path: ROUTES.NOT_FOUND,
    isProtected: false,
    element: lazy(() => import("~/pages/NotFound")),
  },
  {
    path: ROUTES.EMAIL,
    isProtected: false,
    element: lazy(() => import("~/pages/EmailPolicy")),
  },
];

export default routesConfig;
