import React, { FC, Fragment } from "react";
import {
  Box,
  LinearProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import palette from "~/common/mui/theme/palette";
import ArrowDownIcon from "~/common/assets/icons/system/ArrowDownIcon";
import {
  menuStyles,
  selectStyles,
} from "~/layouts/DigestLayout/Header/Settings/Menu/Scheduler/styles";
import { useGetSchedule, useUpdateSchedule } from "~/hooks/app/schedule";
import useStateWithDep from "~/hooks/system/useStateWithDep";
import dayjs from "dayjs";
import {
  formatScheduleToTime,
  formatTime,
} from "~/utils/schedule/formatScheduleToTime";

const MenuScheduler: FC = () => {
  const { mutate: updateSchedule } = useUpdateSchedule();
  const { data: isActualSchedule, isFetched } = useGetSchedule();

  const [selectedTime, setSelectedTime] = useStateWithDep(
    isFetched && isActualSchedule
      ? dayjs(
          formatScheduleToTime((isActualSchedule as any)[0].data.schedule)
        ).format("HH:mm")
      : "08:00"
  );

  const updateFeed = (schedule: any, data: string) => {
    if (schedule) {
      updateSchedule({
        feedId: schedule[0].uuid,
        data: {
          schedule: formatTime(data),
        },
      });
    }
  };

  const handleChangeOption = (e: SelectChangeEvent) => {
    setSelectedTime(e.target.value);
    updateFeed(isActualSchedule, e.target.value);
  };

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Typography
          fontWeight={500}
          fontFamily="Poppins"
          fontSize={16}
          color={palette.white[80]}
          style={{ cursor: "pointer" }}
        >
          Digest delivery time
        </Typography>
      </Box>

      {isFetched ? (
        <Select
          label="Digest delivery time"
          id="digest-delivery-time"
          labelId="digest-delivery-time"
          variant="standard"
          value={selectedTime}
          onChange={handleChangeOption}
          IconComponent={ArrowDownIcon}
          sx={selectStyles}
          MenuProps={{
            sx: menuStyles,
          }}
        >
          <MenuItem value="08:00">
            <span>8:00 morning</span>
            <span>☕</span>
          </MenuItem>
          <MenuItem value="12:00">
            <span>12:00 noon</span>
            <span>☀️</span>
          </MenuItem>
          <MenuItem value="19:00">
            <span>19:00 evening</span>
            <span>🌙</span>
          </MenuItem>
        </Select>
      ) : (
        <LinearProgress sx={{ width: "120px" }} />
      )}
    </Fragment>
  );
};

export default MenuScheduler;
