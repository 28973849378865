import React, { FC, ReactElement } from "react";
import { Box, Container, Toolbar } from "@mui/material";
import { useLocation } from "react-router-dom";
import FlowLayoutHeader from "~/layouts/FlowLayout/Header";
import Step2Background from "~/common/assets/backgrounds/flow/step2";
import Step1Background from "~/common/assets/backgrounds/flow/step1";

type FlowLayoutProps = {
  children: ReactElement;
};

const FlowLayout: FC<FlowLayoutProps> = ({ children }) => {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const activeTab = queryParams.get("step");

  const renderBackground = () => {
    if (Number(activeTab) === 1) return <Step1Background />;
    if (
      Number(activeTab) === 2 ||
      Number(activeTab) === 3 ||
      Number(activeTab) === 4 ||
      Number(activeTab) === 5
    )
      return <Step2Background />;

    return null;
  };

  return (
    <Box sx={{ width: "100%", height: "100vh", position: "relative" }}>
      <FlowLayoutHeader />

      {renderBackground()}

      <main>
        <Toolbar />
        <Container
          maxWidth="appbar"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1,
            position: "relative",
            py: 8,
          }}
        >
          {children}
        </Container>
      </main>
    </Box>
  );
};

export default FlowLayout;
