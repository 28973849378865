import { FC, useEffect } from "react";
import { Box, Button, Divider, Menu, MenuProps } from "@mui/material";
import GmailItem from "~/layouts/DigestLayout/Header/Settings/Menu/GmailAccount";
import palette from "~/common/mui/theme/palette";
import { useSettingsContext } from "~/context/SettingsContext";
import { useAuthContext } from "~/context/AuthContext";
import MenuScheduler from "~/layouts/DigestLayout/Header/Settings/Menu/Scheduler";
import LogoutIcon from "~/common/assets/icons/system/LogoutIcon";
import SupportIcon from "~/common/assets/icons/system/SupportIcon";
import MenuItem from "~/components/shared/Menu/MenuItem";
import GridSwitch from "~/components/shared/Switch/GridSwitch";
import { ROUTES } from "~/common/config/router/routes";
import { useNavigate } from "react-router-dom";
import useGmailIntegration from "~/services/integrations/Gmail";
import { useGetIntegrationConnection } from "~/hooks/app/integrations";

type ISettingsMenu = MenuProps;

const SettingsMenu: FC<
  ISettingsMenu & {
    handleClose: () => void;
  }
> = ({ handleClose, ...menuProps }) => {
  const navigate = useNavigate();

  const { connectionData } = useGmailIntegration(); // Data layer
  const { refetch } = useGetIntegrationConnection("gmail") || {};
  const { viewMode, handleChangeViewMode } = useSettingsContext();
  const { signOut } = useAuthContext();

  useEffect(() => {
    menuProps.open && !connectionData && refetch();
  }, [menuProps.open]);

  const settingsText =
    location.pathname === ROUTES.DIGEST_SETUP
      ? "Back to digest"
      : "Edit digest settings";

  return (
    <Menu
      variant="menu"
      sx={{
        width: ["100%", 400],
      }}
      {...menuProps}
    >
      <MenuItem plain>
        <GmailItem />
      </MenuItem>

      <MenuItem plain>
        {settingsText}

        <Button
          size="small"
          variant="contained"
          color="secondary"
          sx={{ fontWeight: "500" }}
          onClick={() => {
            location.pathname === ROUTES.DIGEST_SETUP
              ? navigate(ROUTES.DIGEST)
              : navigate(ROUTES.DIGEST_SETUP);
            handleClose();
          }}
        >
          {settingsText}
        </Button>
      </MenuItem>

      <Divider />

      <MenuItem plain>
        <MenuScheduler />
      </MenuItem>

      <MenuItem plain>
        3 columns digest
        <GridSwitch
          checked={viewMode === "grid"}
          onChange={() => handleChangeViewMode()}
        />
      </MenuItem>

      <Divider />

      <MenuItem plain>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <SupportIcon />
          Customer support
        </Box>
        <Button
          size="small"
          variant="contained"
          color="secondary"
          sx={{ background: palette.white[16], fontWeight: 500 }}
        >
          Contact
        </Button>
      </MenuItem>

      <Divider />
      <MenuItem
        onClick={() => signOut()}
        sx={{
          justifyContent: "start",
          width: "fit-content",
          pr: 2,
          color: palette.white[32],
          "&:hover": {
            color: palette.white[64],
            path: {
              stroke: palette.white[64],
            },
          },
        }}
      >
        <LogoutIcon fontSize={16} stroke={palette.white[32]} />
        log out
      </MenuItem>
    </Menu>
  );
};

export default SettingsMenu;
