export enum ROUTES {
  HOME = "/",
  // Auth
  LOGIN = "/login",
  SIGNUP = "/signup",
  RESTORE = "/restore",
  // Flow
  WELCOME = "/welcome",

  FLOW_SETUP = "/setup",

  // User
  PROFILE = "/profile",

  // Data policy page for Google email auth
  EMAIL = "/email",

  // Digest
  DIGEST = "/digest",
  DIGEST_BY_UUID = "/digest/:digestId",

  READER_BY_POST_UUID = "/reader/:postId",

  DIGEST_SETUP = "/digest/setup",

  // System
  NOT_FOUND = "*",
  CALLBACK_URL = "/callback",
  CALLBACK_URL_TYPE = "/callback/:integrationType",
}
