import { Auth } from "aws-amplify";

const apiURL = process.env.REACT_APP_API_URL || "";

const awsRegion = process.env.REACT_APP_AWS_REGION || "";
const UserPoolId = process.env.REACT_APP_USER_POOL_ID || "";
const UserPoolWebClientId = process.env.REACT_APP_USER_POOL_CLIENT_ID || "";

// eslint-disable-next-line @typescript-eslint/naming-convention
const custom_header = async () => {
  return {
    Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
  };
};

const prefix = "digest";

// eslint-disable-next-line
export default {
  Auth: {
    region: `${awsRegion}`,
    userPoolId: `${UserPoolId}`,
    userPoolWebClientId: `${UserPoolWebClientId}`,
    custom_header,
  },
  API: {
    endpoints: [
      {
        name: "summarize",
        endpoint: `${apiURL}/openai`,
        region: "eu-central-1",
        custom_header,
      },
      {
        name: "digest",
        endpoint: `${apiURL}/${prefix}/digest`,
        region: "eu-central-1",
        custom_header,
      },
      {
        name: "feeds",
        endpoint: `${apiURL}/${prefix}/feeds`,
        region: "eu-central-1",
        custom_header,
      },
      {
        name: "schedule",
        endpoint: `${apiURL}/${prefix}/feeds`,
        region: "eu-central-1",
        custom_header,
      },
      {
        name: "user",
        endpoint: `${apiURL}/${prefix}/auth`,
        region: "eu-central-1",
        custom_header,
      },
      {
        name: "profile",
        endpoint: `${apiURL}/profile`,
        region: "eu-central-1",
        custom_header,
      },
      {
        name: "rss",
        endpoint: `${apiURL}/rss`,
        region: "eu-central-1",
        custom_header,
      },
      {
        name: "twitter",
        endpoint: `${apiURL}/twitter`,
        region: "eu-central-1",
        custom_header,
      },
      {
        name: "gmail",
        endpoint: `${apiURL}/gmail`,
        region: "eu-central-1",
        custom_header,
      },
      {
        name: "youtube",
        endpoint: `${apiURL}/youtube`,
        region: "eu-central-1",
        custom_header,
      },
      {
        name: "reddit",
        endpoint: `${apiURL}/reddit`,
        region: "eu-central-1",
        custom_header,
      },
    ],
  },
};
