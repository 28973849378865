import React, { FC, ReactElement } from "react";
import { Box, Container, Toolbar } from "@mui/material";
import GuestScreenHeader from "~/components/GuestScreen/Header";
import { useLocation } from "react-router-dom";
import { ROUTES } from "~/common/config/router/routes";
import LoginBackground from "~/common/assets/backgrounds/loginBackground";
import WelcomeBackground from "~/common/assets/backgrounds/welcomeBackground";

type GuestLayoutProps = {
  children: ReactElement;
};

const GuestLayout: FC<GuestLayoutProps> = ({ children }) => {
  const location = useLocation();

  const renderBackground = () => {
    switch (location.pathname) {
      case ROUTES.LOGIN:
      case ROUTES.SIGNUP:
      case ROUTES.RESTORE:
        return <LoginBackground />;
      case ROUTES.WELCOME:
        return <WelcomeBackground />;
      default:
        return null;
    }
  };

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <header>
        <GuestScreenHeader />
      </header>

      {renderBackground()}

      <main>
        <Toolbar />
        <Container
          maxWidth="appbar"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1,
            position: "relative",
            pt: 8,
          }}
        >
          {children}
        </Container>
      </main>
    </Box>
  );
};

export default GuestLayout;
