// eslint-disable-next-line import/no-extraneous-dependencies
import { API } from "@aws-amplify/api";
import { TSourceCodes } from "~/types/sources";
import { TGmailConnectionResponse } from "~/types/integrations/gmail";

const callbackUrl =
  process.env.REACT_APP_INTEGRATIONS_CALLBACK_URL ||
  "https://d1935y0mpjvqfn.cloudfront.net/callback:";

export const initGmailConnection = async (): Promise<{
  redirect_url: string;
}> =>
  API.post(TSourceCodes.GMAIL, "/connection/init", {
    body: {
      redirect_url: `${callbackUrl}/gmail/`, // trailing slash is required!
    },
  });

export const completeGmailConnection = async ({
  authorization_code,
  redirect_url,
}: {
  authorization_code: string;
  redirect_url: string;
}): Promise<TGmailConnectionResponse> =>
  API.post(TSourceCodes.GMAIL, "/connection/complete", {
    body: { authorization_code, redirect_url },
  });

export const getGmailConnection =
  async (): Promise<TGmailConnectionResponse | null> => {
    try {
      return await API.get(TSourceCodes.GMAIL, "/connection/", {});
    } catch (error) {
      console.log(error);
      return null;
    }
  };

export const deleteGmailConnection = async (): Promise<any> => {
  try {
    return await API.del(TSourceCodes.GMAIL, "/connection/", {});
  } catch (error) {
    console.log(error);
  }
};
