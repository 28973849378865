import React, { FC } from "react";
import { SvgIcon } from "@mui/material";

interface IGmailIcon {
  fontSize?: number | string;
}

const GmailIcon: FC<IGmailIcon> = ({ fontSize }) => {
  return (
    <SvgIcon
      width="18"
      height="13"
      viewBox="0 0 18 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ fontSize: fontSize }}
    >
      <path
        d="M1.8014 12.8333H4.44913V6.39558L0.666656 3.55541V11.6973C0.666656 12.3259 1.1754 12.8333 1.8014 12.8333Z"
        fill="#4285F4"
        stroke="none"
      />
      <path
        d="M13.527 12.8333H16.1748C16.8027 12.8333 17.3095 12.324 17.3095 11.6973V3.55541L13.527 6.39558"
        fill="#34A853"
        stroke="none"
      />
      <path
        d="M13.527 1.47264V6.3956L17.3095 3.55544V2.04068C17.3095 0.635732 15.7077 -0.165198 14.5862 0.677387"
        fill="#FBBC04"
        stroke="none"
      />
      <path
        d="M4.44916 6.39561V1.47264L8.98814 4.88085L13.5271 1.47264V6.39561L8.98814 9.80383"
        fill="#EA4335"
        stroke="none"
      />
      <path
        d="M0.666656 2.04067V3.55543L4.44913 6.3956V1.47263L3.39004 0.677379C2.26665 -0.165206 0.666656 0.635724 0.666656 2.04067Z"
        fill="#C5221F"
        stroke="none"
      />
    </SvgIcon>
  );
};

export default GmailIcon;
