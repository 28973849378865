import React, { FC } from "react";
import { Box } from "@mui/material";

const HomeBackground: FC = () => {
  return (
    <Box
      sx={{
        filter: "blur(50px)",
        transition: "all 300ms ease-in-out",
      }}
    >
      <svg
        width="100%"
        height="100vh"
        viewBox="0 0 1440 740"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          position: "fixed",
          zIndex: -1,
        }}
      >
        <g opacity="0.8" filter="url(#filter0_f_906_12536)">
          <path
            d="M359.146 166.623L-228 321.6L265.259 229.068L401.185 434L1108.84 152.715L1277 220.553L1234.96 88L500.678 302.299L359.146 166.623Z"
            fill="url(#paint0_radial_906_12536)"
          />
          <path
            d="M359.146 166.623L-228 321.6L265.259 229.068L401.185 434L1108.84 152.715L1277 220.553L1234.96 88L500.678 302.299L359.146 166.623Z"
            stroke="black"
          />
          <path
            d="M359.146 166.623L-228 321.6L265.259 229.068L401.185 434L1108.84 152.715L1277 220.553L1234.96 88L500.678 302.299L359.146 166.623Z"
            stroke="black"
            strokeOpacity="0.2"
          />
        </g>
        <g filter="url(#filter1_f_906_12536)">
          <path
            d="M1134.85 379.623L1722 534.6L1228.74 442.068L1092.81 647L385.156 365.715L217 433.553L259.039 301L993.322 515.299L1134.85 379.623Z"
            fill="url(#paint1_radial_906_12536)"
          />
          <path
            d="M1134.85 379.623L1722 534.6L1228.74 442.068L1092.81 647L385.156 365.715L217 433.553L259.039 301L993.322 515.299L1134.85 379.623Z"
            stroke="black"
          />
          <path
            d="M1134.85 379.623L1722 534.6L1228.74 442.068L1092.81 647L385.156 365.715L217 433.553L259.039 301L993.322 515.299L1134.85 379.623Z"
            stroke="black"
            strokeOpacity="0.2"
          />
        </g>
        <defs>
          <filter
            id="filter0_f_906_12536"
            x="-528.128"
            y="-212.617"
            width="2105.93"
            height="947.231"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="150"
              result="effect1_foregroundBlur_906_12536"
            />
          </filter>
          <filter
            id="filter1_f_906_12536"
            x="-83.7976"
            y="0.383148"
            width="2105.93"
            height="947.231"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="150"
              result="effect1_foregroundBlur_906_12536"
            />
          </filter>
          <radialGradient
            id="paint0_radial_906_12536"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(235.077 95.9846) rotate(36.0371) scale(565.506 936.364)"
          >
            <stop stopColor="#E1C0FF" />
            <stop offset="0.486726" stopColor="#9282FF" />
            <stop offset="0.728982" stopColor="#9D6FFF" />
            <stop offset="1" stopColor="#EB73FF" />
          </radialGradient>
          <radialGradient
            id="paint1_radial_906_12536"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(1258.92 308.985) rotate(143.963) scale(565.506 936.364)"
          >
            <stop stopColor="#E1C0FF" />
            <stop offset="0.486726" stopColor="#9282FF" />
            <stop offset="0.728982" stopColor="#9D6FFF" />
            <stop offset="1" stopColor="#EB73FF" />
          </radialGradient>
        </defs>
      </svg>
    </Box>
  );
};

export default HomeBackground;
