import React, { FC } from "react";
import { SvgIcon } from "@mui/material";

type IWLogo = {
  fontSize?: number | string;
};

const WLogo: FC<IWLogo> = ({ fontSize }) => {
  return (
    <SvgIcon
      width="408"
      height="271"
      viewBox="0 0 808 671"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ fontSize: fontSize || 38 }}
    >
      <path
        d="M151 339.8C158.8 336.2 165 330.8 155.4 323.7C137.5 310.3 131.5 310.4 131.5 310.4C128 309.4 124.1 309.6 120.6 311.4L10.7 367.9C2.1 372.3 -1.4 382.7 2.7 391.4L32.1 454.1C36.1 462.4 44.5 463.8 53 465.5L70.3 469.4C73.7 468 83.7 451.9 85.2 455.3L150.7 597.8C152.8 602.4 156.9 605.7 161.8 606.9L221.3 622C230.2 624.1 237.1 618.4 241.1 610.1L260.2 570.4L151 339.8Z"
        fill="url(#paint0_linear_1183_10151)"
      />
      <path
        d="M194.1 452.8L287.7 236.1C291.5 227.6 298.5 221 307.1 217.6L353.7 199.4C362.4 196 374.2 193.6 382.2 199.8L395.8 210.3L546.6 613.9C548.4 618.3 548.2 623.3 546.1 627.5L532.5 654.1C529.2 660.6 522.2 667.3 515.1 666L455.9 655.8C445.9 653.9 437.4 644.1 433.1 634.9L326.7 408.2L246 588.2L194.1 452.8Z"
        fill="url(#paint1_linear_1183_10151)"
      />
      <path
        d="M802.3 18C790.9 6.90001 780.2 2.50001 780.2 2.50001C774.8 0.100013 766.8 0.200013 761.1 3.00001L670.6 47.4C662.3 51.5 655.7 58.5 652.2 67.1L485.8 473L544.8 630.5L784.3 28.6L785.4 35.8C785.4 35.8 813.6 29.1 802.3 18Z"
        fill="url(#paint2_linear_1183_10151)"
      />
      <path
        d="M506.4 484.4L672.7 78.4C676.2 69.8 682.8 62.8 691.1 58.7L781.6 14.3C796.2 7.09997 812 21.7 806 36.9L565.4 641.9L506.4 484.4Z"
        fill="url(#paint3_linear_1183_10151)"
      />
      <path
        d="M217 460.4L313.2 245C317 236.5 324 229.9 332.6 226.5L379.2 208.3C387.9 204.9 397.8 209.1 401.4 217.7L569.4 621.6C571.2 626 571 631 568.9 635.2L555.3 661.8C552 668.3 544.8 671.8 537.7 670.4L485.1 660.3C475.1 658.4 466.7 651.8 462.4 642.5L353.4 407.3L268.9 595.9L217 460.4Z"
        fill="url(#paint4_linear_1183_10151)"
      />
      <path
        d="M278.2 576.7L161.1 329.9C157.6 322.5 148.7 319.5 141.4 323.2L37.2 375.6C28.8 379.9 25.3 390.1 29.4 398.6L58.2 460C62.1 468.2 71.7 471.8 80 468.3L98 460.6C101.3 459.2 105.2 460.7 106.7 463.9L170.9 603.4C173 607.9 176.9 611.1 181.7 612.3L237.8 625.7C246.5 627.8 255.5 623.5 259.4 615.4L278.2 576.7Z"
        fill="url(#paint5_linear_1183_10151)"
      />
      <path
        opacity="0.62"
        d="M278.2 575.2L220 453.8L287.7 302.1L353.5 407.3L278.2 575.2Z"
        fill="url(#paint6_linear_1183_10151)"
      />
      <path
        opacity="0.63"
        d="M563.7 344.7L509.4 477.2L571.6 626.3L673.5 370.2L563.7 344.7Z"
        fill="url(#paint7_linear_1183_10151)"
      />
      <path
        opacity="0.64"
        d="M397.5 212L381.2 199.1C381.2 199.1 375.1 195.1 364.8 196.4C354.4 197.7 313.1 215.4 313.1 215.4C313.1 215.4 332 208.7 337.5 212.8C343 216.9 326.8 229.5 326.8 229.5C326.8 229.5 374.2 212.2 377.8 210.8C379.7 210 388.9 206.5 397.5 212Z"
        fill="url(#paint8_linear_1183_10151)"
      />
      <path
        opacity="0.64"
        d="M157.8 325.5L140.6 313.8C140.6 313.8 127.9 307.8 123.8 309.9C116.4 313.7 77.5 334.1 77.5 334.1C77.5 334.1 121.3 314 126.8 318.1C132.3 322.2 92.5 347.8 92.5 347.8C92.5 347.8 139.2 324 142.9 322.6C144.9 321.8 149.3 320.1 157.8 325.5Z"
        fill="url(#paint9_linear_1183_10151)"
      />
      <path
        opacity="0.15"
        d="M330.2 208.2C330.2 208.2 304.5 217.7 302.5 219.8C300.4 221.9 312 232.2 319.7 231.5C327.4 230.8 350.1 219.9 350.1 219.9L330.2 208.2Z"
        fill="url(#paint10_linear_1183_10151)"
      />
      <path
        opacity="0.15"
        d="M35.7 355.6C35.7 355.6 9.8 367.6 7.7 369.8C5.6 371.9 25.9 382.6 32.5 378.5C44 371.4 57.5 365.4 57.5 365.4L35.7 355.6Z"
        fill="url(#paint11_linear_1183_10151)"
      />
      <path
        opacity="0.44"
        d="M316.5 239.1L220 453.8L209.9 432.7L298.2 231.5L316.5 239.1Z"
        fill="url(#paint12_linear_1183_10151)"
      />
      <path
        opacity="0.44"
        d="M674.1 75.4L509.4 471.3L497.9 443.6L653.9 65.9L674.1 75.4Z"
        fill="url(#paint13_linear_1183_10151)"
      />
      <path
        opacity="0.5"
        d="M27.7 395.5L59.5 462.3C59.5 462.3 63 467.1 67.5 468.7L41.9 462.7C41.9 462.7 36.7 461.2 32.1 454.1C27.4 447 1.79999 389.3 1.79999 389.3C1.79999 389.3 -0.60001 381.4 2.39999 376.8C5.29999 372.1 27.7 388.4 27.7 388.4C27.7 388.4 27.2 392.2 27.7 395.5Z"
        fill="url(#paint14_linear_1183_10151)"
      />
      <path
        d="M144.7 323.2C144.7 323.2 39.3 372.6 36.7 376.1C34.1 379.6 27.4 382.5 28.6 393.7C29.9 404.8 48.2 438.7 48.2 438.7C48.2 438.7 31.7 395 33.6 388.9C35.5 382.8 131.5 331.8 137.4 329.4C143.3 327 154.2 324.4 160.1 335.2C166 346 198.2 415.4 198.2 415.4L161 329.8C161.1 329.9 157.3 321.1 144.7 323.2Z"
        fill="url(#paint15_linear_1183_10151)"
      />
      <path
        d="M382.6 207C382.6 207 328.9 227.1 325.7 230C318.1 236.9 309.6 250.3 312.2 248.4C320.8 242.3 362.7 222.2 368.6 219.8C374.6 217.4 389.9 211.4 395.7 222.2C401.6 233 434.5 297.5 434.5 297.5L400.5 214.9C400.8 215 395.3 204.9 382.6 207Z"
        fill="url(#paint16_linear_1183_10151)"
      />
      <path
        d="M786.1 12.7C786.1 12.7 724.8 41 721.6 43.9C714 50.8 677.1 75.6 679.7 73.8C688.3 67.7 767 28.8 772.9 26.5C778.8 24.2 799 16.7 800 28.9C800.6 35.4 754.8 165.7 754.8 165.7C754.8 165.7 798.4 57.3 807.3 33.3C809.6 27 804.9 21.6 804.9 21.6C804.9 21.6 798.7 10.6 786.1 12.7Z"
        fill="url(#paint17_linear_1183_10151)"
      />
      <path
        opacity="0.46"
        d="M177.4 610.6C177.4 610.6 229.4 623.7 237.3 625.1C245.2 626.5 253.8 623.9 257.8 617.1C261.9 610.3 278.1 576.6 278.1 576.6L244.4 505.7C244.4 505.7 268.6 569.6 270.3 575.2C271.9 580.8 255.4 611.7 248.7 616.9C242 622.1 182.8 612.8 177.4 610.6Z"
        fill="url(#paint18_linear_1183_10151)"
      />
      <path
        opacity="0.46"
        d="M293.8 540.3L353.4 407.3C353.4 407.3 353.2 402.2 350.2 405.8C347.2 409.4 286.7 554.4 286.7 554.4L293.8 540.3Z"
        fill="url(#paint19_linear_1183_10151)"
      />
      <path
        d="M732.3 46.1C732.3 46.1 694.5 66.2 686.7 74C678.9 81.8 579.7 319.7 579.7 319.7C579.7 319.7 668.3 81.2 675.7 72.6C683.1 64 701.1 53 724 43C746.9 33 757.6 31.1 757.6 31.1L732.3 46.1Z"
        fill="url(#paint20_linear_1183_10151)"
      />
      <path
        opacity="0.21"
        d="M506.4 474L570.2 623C570.2 623 571.6 630 568.3 636.4C565 642.8 554.7 663 554.7 663C554.7 663 549.7 672.7 535.7 670C521.7 667.3 492.5 661.7 492.5 661.7C492.5 661.7 535.4 663.5 544.2 656.4C553 649.3 561.6 630.2 560.8 623.9C559.9 617.6 506.4 474 506.4 474Z"
        fill="url(#paint21_linear_1183_10151)"
      />
      <path
        opacity="0.46"
        d="M426.2 277L561 601.7C561 601.7 537.4 569.7 506.3 492.5C475.2 415.3 422.3 287.2 420.6 277C418.9 266.8 400.7 226.7 426.2 277Z"
        fill="url(#paint22_linear_1183_10151)"
      />
      <path
        d="M787.4 84.2L575.8 615.8C575.8 615.8 615.5 501 656.9 398.1C698.3 295.2 785.5 76.6 785.5 76.6L787.4 84.2Z"
        fill="url(#paint23_linear_1183_10151)"
      />
      <path
        d="M678.3 43.5C678.3 43.5 664.1 49.9 664.4 53.6C664.8 58.9 678.9 66.6 694.7 57.9C710.4 49.3 801.4 22.9 799 15.3C796.6 7.7 777.3 -3.1 766.7 1.1C756 5.4 678.3 43.5 678.3 43.5Z"
        fill="url(#paint24_linear_1183_10151)"
      />
      <path
        opacity="0.36"
        d="M32.2 454.1C32.2 454.1 56.1 459.5 58.2 460C60.3 460.5 76.3 463.3 90.9 458.6C105.5 453.9 108.8 459.3 110.2 463.3C111.6 467.3 113.4 476.7 113.4 476.7C113.4 476.7 110.7 464.5 105.7 461.2C100.8 457.9 96.1 461.5 91.9 463.3C87.7 465.1 80.3 470.4 67.6 468.8C54.9 467.2 35.1 459.5 32.2 454.1Z"
        fill="url(#paint25_linear_1183_10151)"
      />
      <path
        opacity="0.36"
        d="M42.3 382.1C42.3 382.1 33 384.5 35.6 394.1C38.2 403.7 63.7 458.1 66.7 461.1C69.7 464 82.4 461.1 82.4 461.1C82.4 461.1 69.6 467.6 63.5 464.8C57.4 462 29.5 399.2 28.6 393.6C27.4 386.4 28.8 380.9 40.8 375.8C52.7 370.8 48 378.1 42.3 382.1Z"
        fill="url(#paint26_linear_1183_10151)"
      />
      <path
        opacity="0.42"
        d="M326.1 240.6L223.8 458.3C223.8 458.3 298.5 285.6 302.2 278.3C305.9 271 313.8 237.7 326.1 231.5C338.4 225.3 347.8 224.4 347.8 224.4C347.8 224.4 330.3 231.6 326.1 240.6Z"
        fill="url(#paint27_linear_1183_10151)"
      />
      <path
        d="M53.2 374.1L140.6 330.5C148 326.8 157.1 329.9 160.6 337.5L257.2 544.7L156.9 344.8C155.1 341.4 147.4 334.6 132.7 342.1L53.2 374.1Z"
        fill="url(#paint28_linear_1183_10151)"
      />
      <path
        d="M327.9 240.6L380.2 221.6C386.1 219.5 392.6 222.3 395 228.1L555.2 615.4C557.9 621.8 557.9 629 555.3 635.5L544.9 661.2L549.4 628.7C549.7 626.4 549.4 624 548.5 621.9L385.8 240.1C383.6 235 378.3 232.1 372.9 233L327.9 240.6Z"
        fill="url(#paint29_linear_1183_10151)"
      />
      <path
        d="M736 41.6L787.4 16.6C793.3 14.5 802.9 20.9 803 29.2C803.2 37.7 802.6 38.9 799 48.3C722.2 249.1 592.7 568 590 574.5L579.6 600.2L791 39.4C793.1 31.1 790.7 22.9 779.6 26.3L736 41.6Z"
        fill="url(#paint30_linear_1183_10151)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1183_10151"
          x1="119.249"
          y1="609.788"
          x2="152.191"
          y2="226.716"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0111732" stopColor="#1B0F4F" />
          <stop offset="0.8575" stopColor="#574D99" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1183_10151"
          x1="345.518"
          y1="611.657"
          x2="490.464"
          y2="189.995"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0111732" stopColor="#1B0F4F" />
          <stop offset="0.8575" stopColor="#574D99" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1183_10151"
          x1="538.255"
          y1="458.698"
          x2="837.56"
          y2="-89.086"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0111732" stopColor="#1B0F4F" />
          <stop offset="0.8575" stopColor="#574D99" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1183_10151"
          x1="522.592"
          y1="626.303"
          x2="736.247"
          y2="41.8115"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0374897" stopColor="#2E2594" />
          <stop offset="0.2955" stopColor="#5A2BE0" />
          <stop offset="0.402" stopColor="#6031E1" />
          <stop offset="0.5422" stopColor="#7041E3" />
          <stop offset="0.7007" stopColor="#8C5BE7" />
          <stop offset="0.8203" stopColor="#A574EA" />
          <stop offset="0.919" stopColor="#A888ED" />
          <stop offset="0.9917" stopColor="#AB99EF" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1183_10151"
          x1="360.262"
          y1="658.918"
          x2="482.939"
          y2="243.371"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#36218A" />
          <stop offset="0.401" stopColor="#5A2BE0" />
          <stop offset="0.53" stopColor="#5D2EE0" />
          <stop offset="0.6223" stopColor="#6637E2" />
          <stop offset="0.7031" stopColor="#7646E4" />
          <stop offset="0.777" stopColor="#8C5CE7" />
          <stop offset="0.8393" stopColor="#A574EA" />
          <stop offset="0.927" stopColor="#A888ED" />
          <stop offset="0.9917" stopColor="#AB99EF" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1183_10151"
          x1="157.343"
          y1="652.756"
          x2="146.049"
          y2="219.799"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0231034" stopColor="#5A2BE0" />
          <stop offset="0.2585" stopColor="#5C2EE0" />
          <stop offset="0.4229" stopColor="#6337E2" />
          <stop offset="0.5659" stopColor="#6E46E4" />
          <stop offset="0.6965" stopColor="#7E5CE7" />
          <stop offset="0.8175" stopColor="#9378EB" />
          <stop offset="0.9275" stopColor="#AB99EF" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1183_10151"
          x1="229.166"
          y1="482.894"
          x2="405.862"
          y2="337.487"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00072E" />
          <stop offset="0.9275" stopColor="#8791FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1183_10151"
          x1="522.381"
          y1="522.75"
          x2="758.172"
          y2="328.713"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00072E" />
          <stop offset="0.9275" stopColor="#8791FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1183_10151"
          x1="388.364"
          y1="201.096"
          x2="330.648"
          y2="221.607"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00558659" stopColor="#DAB6FD" />
          <stop offset="0.9275" stopColor="#8791FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_1183_10151"
          x1="148.903"
          y1="314.783"
          x2="92.7517"
          y2="334.738"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00558659" stopColor="#DAB6FD" />
          <stop offset="0.9275" stopColor="#8791FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_1183_10151"
          x1="309.441"
          y1="227.733"
          x2="346.383"
          y2="210.086"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00558659" stopColor="#DAB6FD" />
          <stop offset="0.9275" stopColor="#8791FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_1183_10151"
          x1="14.738"
          y1="376.321"
          x2="54.7867"
          y2="357.189"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00558659" stopColor="#DAB6FD" />
          <stop offset="0.9275" stopColor="#8791FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_1183_10151"
          x1="267.046"
          y1="345.903"
          x2="248.928"
          y2="337.903"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00072E" />
          <stop offset="0.9275" stopColor="#725CAD" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_1183_10151"
          x1="595.56"
          y1="274.034"
          x2="566.859"
          y2="262.758"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00072E" />
          <stop offset="0.9275" stopColor="#725CAD" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_1183_10151"
          x1="45.0994"
          y1="419.14"
          x2="-1.33361"
          y2="434.67"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00072E" />
          <stop offset="0.9275" stopColor="#725CAD" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_1183_10151"
          x1="171.028"
          y1="333.635"
          x2="79.1032"
          y2="439.835"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00558659" stopColor="#DAB6FD" />
          <stop offset="0.9275" stopColor="#8791FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_1183_10151"
          x1="414.554"
          y1="223.892"
          x2="365.147"
          y2="280.971"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00558659" stopColor="#DAB6FD" />
          <stop offset="0.9275" stopColor="#8791FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_1183_10151"
          x1="805.639"
          y1="17.5958"
          x2="722.104"
          y2="114.102"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00558659" stopColor="#DAB6FD" />
          <stop offset="0.9275" stopColor="#8791FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_1183_10151"
          x1="277.866"
          y1="602.42"
          x2="195.981"
          y2="548.3"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00558659" stopColor="#B38EFD" />
          <stop offset="0.9275" stopColor="#8791FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_1183_10151"
          x1="333.433"
          y1="441.443"
          x2="292.02"
          y2="563.643"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00558659" stopColor="#B38EFD" />
          <stop offset="0.9275" stopColor="#8791FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_1183_10151"
          x1="609.328"
          y1="132.404"
          x2="705.331"
          y2="202.053"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00558659" stopColor="#DAB6FD" />
          <stop offset="0.9275" stopColor="#8791FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_1183_10151"
          x1="582.278"
          y1="603.556"
          x2="501.57"
          y2="568.731"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00558659" stopColor="#DAB6FD" />
          <stop offset="0.9275" stopColor="#8791FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_1183_10151"
          x1="499.642"
          y1="420.753"
          x2="468.582"
          y2="435.812"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00558659" stopColor="#DAB6FD" />
          <stop offset="0.9275" stopColor="#8791FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_1183_10151"
          x1="924.037"
          y1="228.23"
          x2="376.942"
          y2="493.488"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00558659" stopColor="#DAB6FD" />
          <stop offset="0.9275" stopColor="#8791FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_1183_10151"
          x1="791.107"
          y1="6.9292"
          x2="680.672"
          y2="49.9111"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00558659" stopColor="#DAB6FD" />
          <stop offset="0.9275" stopColor="#8791FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_1183_10151"
          x1="88.8331"
          y1="465.899"
          x2="30.0075"
          y2="463.31"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00558659" stopColor="#DAB6FD" />
          <stop offset="0.9275" stopColor="#8791FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_1183_10151"
          x1="42.1931"
          y1="427.122"
          x2="55.4286"
          y2="421.213"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00558659" stopColor="#DAB6FD" />
          <stop offset="0.9275" stopColor="#8791FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint27_linear_1183_10151"
          x1="264.086"
          y1="328.109"
          x2="303.381"
          y2="352.11"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00558659" stopColor="#DAB6FD" />
          <stop offset="0.9275" stopColor="#8791FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint28_linear_1183_10151"
          x1="220.54"
          y1="405.851"
          x2="152.773"
          y2="461.382"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A1EDFF" />
          <stop offset="0.9275" stopColor="#8791FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint29_linear_1183_10151"
          x1="503.712"
          y1="415.293"
          x2="447.867"
          y2="444.784"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A1EDFF" />
          <stop offset="0.9275" stopColor="#8791FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint30_linear_1183_10151"
          x1="944.738"
          y1="169.342"
          x2="572.041"
          y2="366.159"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A1EDFF" />
          <stop offset="0.9275" stopColor="#8791FF" stopOpacity="0" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};

export default WLogo;
