import { useLocation, useNavigate } from "react-router-dom";
import { ReactElement, useEffect } from "react";
import AuthorizedArea from "~/router/AuthorizedArea";
import GuestArea from "~/router/GuestArea";
import { ROUTES } from "~/common/config/router/routes";
import useAuth from "~/hooks/app/auth/useAuth";
import GuestScreen from "~/pages/HomeScreen";
import { useGetProfile } from "~/hooks/app/profile";

interface CheckAuthProps {
  children: ReactElement;
  isProtected?: boolean;
  redirect?: string;
}

const CheckAuth: React.FC<CheckAuthProps> = ({
  children,
  redirect,
  isProtected,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { isLoading, currentUser } = useAuth();
  const isAuthenticated = !!currentUser;
  const { data: isActualOnboard, isLoading: isActualOnboardLoading } =
    useGetProfile();

  useEffect(() => {
    if (isLoading) return;

    if (!isAuthenticated && isProtected) {
      navigate(ROUTES.LOGIN, { state: { from: location }, replace: true });
    }

    if (isAuthenticated && redirect) {
      navigate(redirect, { state: { from: location }, replace: true });
    }

    if (
      !!currentUser &&
      isActualOnboard?.data.onboard_step !== 6 &&
      isActualOnboard
    ) {
      if (
        location.pathname !== ROUTES.FLOW_SETUP &&
        !location.pathname.startsWith(ROUTES.CALLBACK_URL)
      ) {
        navigate(
          `${ROUTES.FLOW_SETUP}?step=${isActualOnboard?.data.onboard_step || 1}`
        );
      }
    } else if (
      !!currentUser &&
      isActualOnboard?.data.onboard_step !== 6 &&
      isActualOnboard
    ) {
      if (location.pathname !== ROUTES.WELCOME) {
        navigate(ROUTES.WELCOME);
      }
    }
  }, [
    isLoading,
    isAuthenticated,
    isProtected,
    currentUser,
    location,
    navigate,
    redirect,
    isActualOnboardLoading,
  ]);

  if (isLoading) return null;

  if (!isAuthenticated && isProtected) {
    return <GuestScreen />;
  }

  if (isAuthenticated) {
    return <AuthorizedArea>{children}</AuthorizedArea>;
  } else {
    return <GuestArea>{children}</GuestArea>;
  }
};

export default CheckAuth;
