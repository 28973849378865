// eslint-disable-next-line import/no-extraneous-dependencies
import { API } from "@aws-amplify/api";
import { TFeed, TFeedData } from "~/types/digest/feed";
import { API_CONFIG } from "~/common/config/api";

// list: getFeedListAPICall,
// item: getFeedItemAPICall,
// itemCreate: createFeedItemAPICall,
// itemUpdate: updateFeedItemAPICall,
// itemDelete: deleteFeedItemAPICall,

export const getFeedListAPICall = async (): Promise<TFeed[]> =>
  API.get(API_CONFIG.feeds.resource, "", {});

export const getFeedItemAPICall = async (feedId: string): Promise<TFeed> =>
  API.get(API_CONFIG.feeds.resource, `/${feedId}`, {});

export const createFeedItemAPICall = async (
  data: Pick<TFeedData, "title" | "emoji">
): Promise<TFeed> =>
  API.post(API_CONFIG.feeds.resource, "", {
    body: {
      ...data,
    },
  });

export const updateFeedItemAPICall = async (
  feedId: string,
  data: Partial<TFeedData>
): Promise<TFeed> => {
  //console.log("feedData:", data);
  return API.patch(API_CONFIG.feeds.resource, `/${feedId}`, {
    body: data,
  });
};

export const deleteFeedItemAPICall = async (feedId: string): Promise<any> =>
  API.del(API_CONFIG.feeds.resource, `/${feedId}`, {});
