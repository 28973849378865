import React, { FC, ReactNode } from "react";
import { MenuItem as MuiMenuItem, MenuItemProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import palette from "~/common/mui/theme/palette";

type IMenuItem = MenuItemProps & {
  children: ReactNode;
  plain?: boolean;
};

const StyledMenuItem = styled(MuiMenuItem, {
  shouldForwardProp: (prop) => prop !== "plain",
})<{
  plain?: boolean;
}>(({ plain }) => ({
  background: "transparent",
  cursor: plain ? "default" : "pointer",
  "&:hover": {
    backgroundColor: plain ? "transparent" : palette.white[4],
  },
}));

const MenuItem: FC<IMenuItem> = ({ children, ...props }) => {
  return <StyledMenuItem {...props}>{children}</StyledMenuItem>;
};

export default MenuItem;
