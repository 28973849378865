import { useEffect, useState } from "react";

export default function useStateWithDep<T>(
  defaultValue: T | undefined
): [value: T | undefined, setValue: any] {
  const [value, setValue] = useState<T | undefined>(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);
  return [value, setValue];
}
