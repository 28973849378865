import { IUnitAPIv2 } from "~/types/core/api";
import {
  getFeedListAPICall,
  getFeedItemAPICall,
  createFeedItemAPICall,
  updateFeedItemAPICall,
  deleteFeedItemAPICall,
} from "~/api/feeds";
import {
  createFeedSourceItemAPICall,
  deleteFeedSourceItemAPICall,
  getFeedSourceItemAPICall,
  getFeedSourcesListAPICall,
} from "~/api/sources";
import {
  createScheduleAPICall,
  getScheduleAPICall,
  updateScheduleAPICall,
} from "~/api/schedule";
import {
  getRssItemAPICall,
  getRssListAPICall,
  createRssItemAPICall,
  searchRssItemAPICall,
} from "~/api/services/rss";

import {
  fetchDigestAPICall,
  generateDigestAPICall,
  getDigestAPICall,
  getDigestListAPICall,
} from "~/api/digest";
import {
  completeGmailConnection,
  deleteGmailConnection,
  getGmailConnection,
  initGmailConnection,
} from "~/api/services/gmail/auth";
import {
  createGmailItemAPICall,
  searchGmailItemAPICall,
} from "~/api/services/gmail";
import {
  completeRedditConnection,
  deleteRedditConnection,
  getRedditConnection,
  initRedditConnection,
} from "~/api/services/reddiit/auth";
import {
  createRedditItemAPICall,
  searchRedditItemAPICall,
} from "~/api/services/reddiit";
import {
  createTwitterItemAPICall,
  searchTwitterAccountAPICall,
} from "~/api/services/twitter";
import { getProfileAPICall, updateProfileAPICall } from "~/api/profile";
import { createSummarizeAPICall } from "~/api/summarize";

export const unitAPIv2: IUnitAPIv2 = {
  digest: {
    feeds: {
      list: getFeedListAPICall,
      item: getFeedItemAPICall,
      itemCreate: createFeedItemAPICall,
      itemUpdate: updateFeedItemAPICall,
      itemDelete: deleteFeedItemAPICall,
      getSchedule: getScheduleAPICall,
      createSchedule: createScheduleAPICall,
      updateSchedule: updateScheduleAPICall,
    },
    sources: {
      list: getFeedSourcesListAPICall,
      item: getFeedSourceItemAPICall,
      itemCreate: createFeedSourceItemAPICall,
      itemDelete: deleteFeedSourceItemAPICall,
    },
    // digest
    get: getDigestAPICall,
    list: getDigestListAPICall,
    fetch: fetchDigestAPICall,
    generate: generateDigestAPICall,
  },
  services: {
    rss: {
      list: getRssListAPICall,
      item: getRssItemAPICall,
      itemCreate: createRssItemAPICall,
      itemSearch: searchRssItemAPICall,
    },
    gmail: {
      // auth
      init: initGmailConnection,
      connection: getGmailConnection,
      deleteConnection: deleteGmailConnection,
      // data
      complete: completeGmailConnection,
      itemSearch: searchGmailItemAPICall,
      itemCreate: createGmailItemAPICall,
    },
    reddit: {
      // auth
      init: initRedditConnection,
      connection: getRedditConnection,
      deleteConnection: deleteRedditConnection,
      // data
      complete: completeRedditConnection,
      itemSearch: searchRedditItemAPICall,
      itemCreate: createRedditItemAPICall,
    },
    twitter: {
      itemSearch: searchTwitterAccountAPICall,
      itemCreate: createTwitterItemAPICall,
    },
  },
  profile: {
    get: getProfileAPICall,
    put: updateProfileAPICall,
  },
  summarize: {
    post: createSummarizeAPICall,
  },
};
