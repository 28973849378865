export const API_CONFIG = {
  digest: {
    resource: "digest",
    queryListKey: "digests",
    queryItemKey: "digest",
    messages: {
      success: {
        update: "Digest updated!",
      },
      error: {
        main: "Something went wrong: ",
      },
    },
  },

  feeds: {
    resource: "feeds",
    queryListKey: "feeds",
    queryItemKey: "feed",
    messages: {
      success: {
        update: "Topic updated!",
        create: "Topic settled",
        delete: "Topic removed",
      },
      error: {
        main: "Something went wrong: ",
      },
    },
  },
  sources: {
    resource: "sources",
    // Query keys, used in ~/hooks for React-Query lib.
    // https://tanstack.com/query/v4/docs/react/guides/queries,
    queryListKey: "sources",
    queryItemKey: "source",
    // CRUD messages for UI purposes
    messages: {
      success: {
        update: "Changes saved",
        create: "Source added",
        delete: "Source removed",
      },
      error: {
        main: "Something went wrong: ",
      },
    },
  },
  schedule: {
    resource: "schedule",
    queryItemKey: "schedule",
    messages: {
      success: {
        update: "Schedule updated!",
        create: "Schedule created!",
        delete: "Schedule deleted!",
      },
      error: {
        main: "Something went wrong: ",
      },
    },
  },
  rss: {
    resource: "rss",
    queryListKey: ["sources", "rss"],
    queryItemKey: "rss",
    querySearchKey: "rss/search",
  },
  twitter: {
    resource: "twitter",
    queryListKey: ["sources", "twitter"],
    queryItemKey: "twitter",
    querySearchKey: "twitter/search",
  },
  gmail: {
    resource: "gmail",
    queryListKey: ["sources", "gmail"],
    queryItemKey: "gmail",
    querySearchKey: "gmail/search",
  },
  profile: {
    resource: "profile",
    queryItemKey: "profile",
    messages: {
      error: {
        main: "Something went wrong: ",
      },
    },
  },
  summarize: {
    resource: "summarize",
    queryItemKey: "summarize",
    messages: {
      error: {
        main: "Something went wrong: ",
      },
    },
  },
};
