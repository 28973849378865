import { FC, Suspense } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import CheckAuth from "~/router/CheckAuth";
import config, { TRoute } from "~/common/config/router/config";
// import TransitionWrapper from "~/router/TransitionWrapper";

const RenderElement = ({
  isProtected,
  redirect,
  outlet,
  fallback,
  element: Element,
}: Pick<TRoute, "isProtected" | "redirect" | "element" | "fallback"> & {
  outlet?: boolean;
}) => (
  <CheckAuth isProtected={isProtected} redirect={redirect}>
    <Suspense fallback={fallback}>
      {/*<TransitionWrapper>*/}
      <Element />
      {outlet && <Outlet />}
      {/*</TransitionWrapper>*/}
    </Suspense>
  </CheckAuth>
);

const EntryPoint: FC = () => {
  return (
    <Routes>
      {config.map(
        (
          { path, element: Element, isProtected, redirect, children },
          index
        ) => {
          if (!children) {
            return (
              <Route
                path={path}
                key={index}
                element={
                  <RenderElement
                    isProtected={isProtected}
                    redirect={redirect}
                    element={Element}
                  />
                }
              />
            );
          } else {
            return (
              <Route
                path={path}
                key={index}
                element={
                  <RenderElement
                    outlet
                    isProtected={isProtected}
                    redirect={redirect}
                    element={Element}
                  />
                }
              >
                {children.map((child, childIndex) => (
                  <Route
                    path={child.path}
                    key={childIndex}
                    element={
                      <RenderElement
                        outlet
                        isProtected={child.isProtected}
                        redirect={child.redirect}
                        element={child.element}
                      />
                    }
                  />
                ))}
              </Route>
            );
          }
        }
      )}
    </Routes>
  );
};

export default EntryPoint;
