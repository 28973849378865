// eslint-disable-next-line import/no-extraneous-dependencies
import { API } from "@aws-amplify/api";
import { TSourceCodes } from "~/types/sources";
import { TTwitter, TTwitterSearchAccount } from "~/types/sources/twitter";

export const searchTwitterAccountAPICall = async ({
  name,
}: {
  name?: string;
}): Promise<TTwitterSearchAccount[]> => {
  return API.get(TSourceCodes.TWITTER, `/search/?name=${name}`, {});
};

export const createTwitterItemAPICall = async (
  data: TTwitterSearchAccount
): Promise<TTwitter> =>
  API.post(TSourceCodes.TWITTER, "/add-source/", {
    body: data,
  });
