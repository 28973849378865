import React, { FC } from "react";
import { Avatar, Box, IconButton } from "@mui/material";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";
import { motion } from "framer-motion";
import palette from "~/common/mui/theme/palette";
import { avatarAnimation } from "~/layouts/DigestLayout/Header/Settings/animations";
import GearIcon from "~/common/assets/icons/system/GearIcon";
import SettingsMenu from "~/layouts/DigestLayout/Header/Settings/Menu";

const UserSettings: FC = () => {
  const popupState = usePopupState({
    variant: "dialog",
    popupId: "settings",
  });

  const handleCloseMenu = () => {
    popupState.close();
  };

  return (
    <Box display="flex" gap={1}>
      <IconButton {...bindTrigger(popupState)}>
        <motion.div {...avatarAnimation}>
          <Avatar sx={{ width: 38, height: 38, bgcolor: palette.white[16] }}>
            <GearIcon />
          </Avatar>
        </motion.div>
      </IconButton>

      <SettingsMenu
        {...bindMenu(popupState)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        handleClose={handleCloseMenu}
      />
    </Box>
  );
};

export default UserSettings;
