// eslint-disable-next-line import/no-extraneous-dependencies
import { API } from "@aws-amplify/api";
import { TSource, TSourceType } from "~/types/sources";
import { API_CONFIG } from "~/common/config/api";

// list: getFeedSourcesListAPICall,
// item: getFeedSourceItemAPICall,
// itemCreate: createFeedSourceItemAPICall,
// itemUpdate: updateFeedItemAPICall,
// itemDelete: deleteFeedItemAPICall,

export const getFeedSourcesListAPICall = async (
  topicId?: string
): Promise<TSource<TSourceType>[]> =>
  API.get(API_CONFIG.feeds.resource, `/${topicId}/sources`, {});

export const getFeedSourceItemAPICall = async (
  feedId: string,
  sourceId: string
): Promise<TSource<TSourceType>> =>
  API.get(API_CONFIG.feeds.resource, `/${feedId}/sources/${sourceId}`, {});

export const createFeedSourceItemAPICall = async ({
  sourceData,
  feedId,
}: {
  sourceData: TSourceType;
  feedId: string;
}): Promise<TSource<TSourceType>> =>
  API.post(API_CONFIG.feeds.resource, `/${feedId}/sources`, {
    body: {
      ...sourceData,
    },
  });

// Not used. Better update the whole feed
// export const updateFeedSourceItemAPICall = async (
//   data: Partial<TSource<TSourceType>>
// ): Promise<TFeed> =>
//   API.patch("feeds", `/${data.uuid}`, {
//     body: {
//       ...data,
//     },

//   });

export interface UpdateFeedSourceKeywordsData {
  keywords: string[];
  posts_limit: number;
}

export const updateFeedSourceKeywordsAPICall = async ({
  topicId,
  sourceId,
  data,
}: {
  topicId: string;
  sourceId: string;
  data: UpdateFeedSourceKeywordsData;
}): Promise<any> =>
  API.patch("feeds", `/${topicId}/sources/${sourceId}`, {
    body: {
      ...data,
    },
  });

export const deleteFeedSourceItemAPICall = async ({
  feedId,
  sourceId,
}: {
  feedId: string;
  sourceId: string;
}): Promise<any> =>
  API.del(API_CONFIG.feeds.resource, `/${feedId}/sources/${sourceId}`, {});
