import React, { FC } from "react";
import { styled, Switch, SwitchProps } from "@mui/material";
import palette from "~/common/mui/theme/palette";

type IHugeSwitch = SwitchProps;

const SwitchStyled = styled(Switch)(({ theme }) => ({
  width: 48,
  height: 27,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 21,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 3,
    "&.Mui-checked": {
      transform: "translateX(21px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor:
          theme.palette.mode === "dark"
            ? "#177ddc"
            : theme.palette.success.main,
      },
      "&.Mui-disabled": {
        color: palette.white[64],
        "& + .MuiSwitch-track": {
          backgroundColor: palette.white[8],
        },
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 21,
    height: 21,
    borderRadius: 20,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
  ".Mui-disabled+.MuiSwitch-track": {
    opacity: 0.5,
  },
}));

const HugeSwitch: FC<IHugeSwitch> = (props) => {
  return <SwitchStyled {...props} />;
};
export default HugeSwitch;
