import { ReactNode, useContext, createContext, useState } from "react";
import { DEFAULT_POST_VIEW } from "~/common/config";
import { Backdrop } from "@mui/material";
import palette from "~/common/mui/theme/palette";
import { useGetProfile, useUpdateProfile } from "~/hooks/app/profile";
import useStateWithDep from "~/hooks/system/useStateWithDep";

interface ISettingsContext {
  viewMode: string | undefined;
  isBackdrop: boolean;
  handleBackdrop: (value?: boolean) => void;
  handleChangeViewMode: (mode?: "grid" | "list") => void;
}

export const SettingsContext = createContext<ISettingsContext>({
  viewMode: DEFAULT_POST_VIEW,
  isBackdrop: false,
  handleBackdrop: () => {},
  handleChangeViewMode: () => {},
});

export const useSettingsContext = () => useContext(SettingsContext);

export const SettingsProvider = ({ children }: { children: ReactNode }) => {
  const [isBackdrop, setIsBackdrop] = useState(false);

  const handleBackdrop = (value?: boolean) => {
    setIsBackdrop((prev) => value || !prev);
  };
  const { data: isActualGrid, isLoading: isActualGridLoading } =
    useGetProfile();
  const { mutate: updateGrid } = useUpdateProfile();
  const [viewMode, setViewMode] = useStateWithDep(
    isActualGridLoading ? "grid" : isActualGrid?.data.view_mode
  );

  const handleChangeViewMode = () => {
    const view = viewMode === "grid" ? "list" : "grid";
    setViewMode(view);
    updateGrid({
      ...isActualGrid?.data,
      view_mode: view,
    });
  };

  return (
    <SettingsContext.Provider
      value={{
        viewMode,
        isBackdrop,
        handleBackdrop,
        handleChangeViewMode,
      }}
    >
      {children}
      <Backdrop
        open={isBackdrop}
        sx={{ zIndex: 999, background: palette.black[16] }}
      />
    </SettingsContext.Provider>
  );
};
