// eslint-disable-next-line import/no-extraneous-dependencies
import { API } from "@aws-amplify/api";
import { TRss, TRssSearchData } from "~/types/sources/rss";
import { API_CONFIG } from "~/common/config/api";

export const getRssListAPICall = async (): Promise<[TRss[]]> =>
  API.get(API_CONFIG.rss.resource, "/sources", {});

export const getRssItemAPICall = async (rssId: string): Promise<TRss> =>
  API.get(API_CONFIG.rss.resource, `/sources/${rssId}`, {});

export const createRssItemAPICall = async (
  data: TRssSearchData
): Promise<TRss> =>
  API.post(API_CONFIG.rss.resource, "/add-source/", {
    body: data,
  });

// ===== Not used. Just for reference. ======
// export const deleteRssItemAPICall = async (id: string): Promise<any> =>
//   API.del(API_CONFIG.rss.resource, `/sources/${id}`, {});

export const searchRssItemAPICall = async (
  searchURL: string
): Promise<TRssSearchData[]> =>
  API.post(API_CONFIG.rss.resource, "/search", {
    body: {
      url: searchURL,
      source: "rss",
    },
  });
