import { TGmailConnectionResponse } from "~/types/integrations/gmail";
import { TRedditConnectionResponse } from "~/types/integrations/reddit";

export enum TIntegrationTypesEnum {
  "gmail",
  // "reddit",
}

export type TIntegrationTypes = "gmail" | "reddit";

export type TConnectionResponse =
  | TGmailConnectionResponse
  | TRedditConnectionResponse;
