import React, { FC } from "react";
import { AppBar, Container, Toolbar } from "@mui/material";
import Logo from "~/components/shared/Logo";
import UserSettings from "~/layouts/DigestLayout/Header/Settings";
import ProgressBar from "~/components/shared/ProgressBar";
// import UpcomingFeatures from "../UpcomingFeatures";
import { useLocation } from "react-router-dom";
import { ROUTES } from "~/common/config/router/routes";

const DigestLayoutHeader: FC = () => {
  const location = useLocation();

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, height: 72 }}
    >
      <Container maxWidth="appbar" sx={{ padding: ["0 4px", "0 16px"] }}>
        <Toolbar disableGutters sx={{ height: 72 }}>
          <Logo />
          {/* <UpcomingFeatures /> */}
          <UserSettings />
        </Toolbar>
      </Container>
      {location.pathname !== ROUTES.DIGEST_SETUP && <ProgressBar />}
    </AppBar>
  );
};

export default DigestLayoutHeader;
