import { SvgIcon } from "@mui/material";
import React, { FC } from "react";
import palette from "~/common/mui/theme/palette";

type ISupportIcon = {
  fontSize?: number;
  fill?: string;
  stroke?: string;
};

const SupportIcon: FC<ISupportIcon> = ({ fontSize, fill, stroke }) => {
  return (
    <SvgIcon
      width="18"
      height="17"
      viewBox="0 0 18 17"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ fontSize: fontSize || 14 }}
    >
      <path
        d="M13.1667 4.16665H14.8333C15.7538 4.16665 16.5 4.91284 16.5 5.83331V10.8333C16.5 11.7538 15.7538 12.5 14.8333 12.5H13.1667V15.8333L9.83333 12.5H6.5C6.03976 12.5 5.6231 12.3134 5.32149 12.0118M5.32149 12.0118L8.16667 9.16665H11.5C12.4205 9.16665 13.1667 8.42045 13.1667 7.49998V2.49998C13.1667 1.57951 12.4205 0.833313 11.5 0.833313H3.16667C2.24619 0.833313 1.5 1.57951 1.5 2.49998V7.49998C1.5 8.42045 2.24619 9.16665 3.16667 9.16665H4.83333V12.5L5.32149 12.0118Z"
        stroke={stroke || palette.white[64]}
        fill={fill || "none"}
        strokeOpacity="0.64"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default SupportIcon;
