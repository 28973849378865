// Sources
export const MAX_SOURCES_COUNT = 20;
export const MAX_PLACEHOLDER_COUNT = 4;

// Colors
export const RANDOM_AVATAR_COLORS = [
  "#deb88780", // BurlyWood
  "#ff634769", // MediumVioletRed
  "#4682b499", // SteelBlue
  "#5f9ea080", // CadetBlue
  "#2e8b5785", // SeaGreen
  "#87ceeb80", // SkyBlue
  "#da70d680", // Orchid
  "#9370db80", // MediumPurple
];

// Posts
export const MIN_DESCRIPTION_COUNT = 200;
export const DESCRIPTION_TRIM_COUNT = 300;

export const DEFAULT_POST_VIEW = "grid";

// Reader
export const DIALOG_FULLSCREEN = true;

export const READER_DIALOG_WIDTH = 1000;
export const READER_DIALOG_CONTENT_MAX_WIDTH = 650;
export const READER_CONTENT_BG_COLOR = "#14131A";

// Search
export const SEARCH_DEBOUNCE_TIME = 500;
export const SEARCH_IS_RETRY = false;

// Keywords
export const KEYWORDS_DEBOUNCE_TIME = 500;
export const KEYWORDS_MAX_COUNT = 3;

// Animations
export const REDUCED_MOTION = "user";

// user: Respect user's device setting.
// always: Enforce reduced motion.
// never: Don't reduce motion.
