import React, { useCallback, useContext, useEffect, useState } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Auth } from "@aws-amplify/auth";
import { AuthContext } from "~/context/AuthContext";
import { Hub } from "aws-amplify";
import {
  SignInInput,
  TUserAttributes,
  ConfirmSignUpInput,
  ForgotPasswordInput,
  ResendSignUpInput,
  ResetPasswordInput,
  SignUpInput,
  TUser,
} from "~/types/user";
import { useQueryClient } from "@tanstack/react-query";

export type TResponseData<T> = {
  error: boolean;
  data?: T;
  message?: string;
};

const getCurrentUser = async (): Promise<TUser | null> => {
  try {
    return await Auth.currentAuthenticatedUser();
  } catch (_e) {
    // currentAuthenticatedUser throws an Error if not signed in
    return null;
  }
};

const useAuth = () => {
  const [currentUser, setCurrentUser] = useState<TUser | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const queryClient = useQueryClient();

  useEffect(() => {
    const updateUser = async () => {
      setCurrentUser(await getCurrentUser().then());
      setIsLoading(false);
    };
    Hub.listen("auth", updateUser); // listen for login/signup events
    updateUser().then(); // check manually the first time because we won't get a Hub event
    //return () => Hub.remove("auth", updateUser);
  }, []);

  const signIn = useCallback(
    async ({ email, password }: SignInInput) => {
      setCurrentUser(await Auth.signIn(email, password));
    },
    [setCurrentUser]
  );

  const signOut = useCallback(async () => {
    await Auth.signOut();
    queryClient.invalidateQueries();
    setCurrentUser(null);
  }, [setCurrentUser]);

  const deleteUser = useCallback(async () => {
    currentUser?.deleteUser((error?: Error) => {
      if (error) throw error;

      setCurrentUser(null);
      queryClient.invalidateQueries();
    });
  }, [currentUser, setCurrentUser]);

  return { currentUser, isLoading, signIn, signOut, deleteUser };
};

export const useUser = (): TUserAttributes | null => {
  const { currentUser } = React.useContext(AuthContext);
  if (!currentUser) return null;

  // See https://github.com/aws-amplify/amplify-js/issues/4927
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return currentUser.attributes;
};

export function useSignIn() {
  return useContext(AuthContext).signIn;
}

export function useSignOut() {
  return useContext(AuthContext).signOut;
}

export function useSignUp() {
  return async function signUp({
    name,
    email,
    password,
  }: SignUpInput): Promise<
    TResponseData<{ userConfirmed?: boolean; user: TUser } | null>
  > {
    try {
      const { userConfirmed, user } = await Auth.signUp({
        username: email,
        password,
        attributes: { name, email },
      });
      return { error: false, data: { userConfirmed, user } };
    } catch (e) {
      console.log(e);
      const message = (e as Error).message;
      return { error: true, message };
    }
  };
}

export function useConfirmSignUp() {
  return async function confirmSignUp({
    email,
    code,
  }: ConfirmSignUpInput): Promise<TResponseData<any>> {
    try {
      const data = await Auth.confirmSignUp(email, code);
      return { error: false, data };
    } catch (e) {
      console.log(e);
      const message = (e as Error).message;
      return { error: true, message };
    }
  };
}

export function useResendSignUp() {
  return async function resendSignUp({ email }: ResendSignUpInput) {
    await Auth.resendSignUp(email);
  };
}

export function useForgotPassword() {
  return async function forgotPassword({ email }: ForgotPasswordInput): Promise<
    TResponseData<{
      CodeDeliveryDetails: {
        AttributeName: string;
        DeliveryMedium: string;
        Destination: string;
      };
    }>
  > {
    try {
      const data = await Auth.forgotPassword(email);
      return { error: false, data };
    } catch (e) {
      console.log(e);
      const message = (e as Error).message;
      return { error: true, message };
    }
  };
}

export function useResetPassword() {
  return async function resetPassword({
    email,
    code,
    password,
  }: ResetPasswordInput): Promise<TResponseData<string | null>> {
    try {
      const data = await Auth.forgotPasswordSubmit(email, code, password);
      return { error: false, data };
    } catch (e) {
      console.log(e);
      const message = (e as Error).message;
      return { error: true, message };
    }
  };
}

export function useDeleteUser() {
  return useContext(AuthContext).deleteUser;
}

export default useAuth;
export { getCurrentUser };
