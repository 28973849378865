import React, { FC } from "react";
import { Box } from "@mui/material";
import integrations_bg from "~/common/assets/images/integrations_bg.png";

const Step1Background: FC = () => (
  <Box
    sx={{
      position: "fixed",
      bottom: 0,
      left: 0,
      right: 0,
      opacity: 0.8,
      backgroundImage: `url(${integrations_bg})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center bottom", // Set the background position
      height: "500px",
      filter: "blur(10px)",
      transition: "all 0.5s fade-in-out",
    }}
  />
);

export default Step1Background;
