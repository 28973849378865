// eslint-disable-next-line import/no-extraneous-dependencies
import { API } from "@aws-amplify/api";
import { TSourceCodes } from "~/types/sources";
import { TReddit, TRedditSearchSubReddit } from "~/types/sources/reddit";

export const searchRedditItemAPICall = async ({
  name,
}: {
  name?: string;
}): Promise<TRedditSearchSubReddit[]> => {
  return API.get(TSourceCodes.REDDIT, `/search/?name=${name}`, {});
};

export const createRedditItemAPICall = async (
  data: TRedditSearchSubReddit
): Promise<TReddit> =>
  API.post(TSourceCodes.REDDIT, "/add-source/", {
    body: data,
  });
