import { SvgIcon } from "@mui/material";
import React, { FC } from "react";
import palette from "~/common/mui/theme/palette";

type ILogoutIcon = {
  fontSize?: number;
  fill?: string;
  stroke?: string;
};

const LogoutIcon: FC<ILogoutIcon> = ({ fontSize, fill, stroke }) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ fontSize: fontSize || 20 }}
    >
      <path
        d="M17 16L21 12M21 12L17 8M21 12L7 12M13 16V17C13 18.6569 11.6569 20 10 20H6C4.34315 20 3 18.6569 3 17V7C3 5.34315 4.34315 4 6 4H10C11.6569 4 13 5.34315 13 7V8"
        stroke={stroke || palette.white[64]}
        strokeWidth="2"
        fill={fill || "none"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default LogoutIcon;
