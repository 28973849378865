import buttons from "./buttons";
import radio from "./radio";
import checkbox from "./checkbox";
import select from "./select";
import palette from "~/common/mui/theme/palette";
import textfield from "~/common/mui/theme/components/textfield";
import { DIALOG_FULLSCREEN } from "~/common/config";
import menu from "~/common/mui/theme/components/menu";
import tabs from "~/common/mui/theme/components/tabs";

const components = {
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: "#26252B",
      },
      arrow: {
        color: "#26252B",
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      color: palette.white[64],
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      fontSizeSmall: {
        fontSize: 16,
      },
    },
  },

  MuiAutocomplete: {
    styleOverrides: {
      root: {
        "& .MuiAutocomplete-inputRoot": {
          display: "flex",
          gap: 8,
          [`& fieldset`]: {
            borderWidth: 0,
          },
        },
        ".MuiInputBase-root": {
          "&:hover": {
            [`& fieldset`]: {
              borderWidth: 0,
              borderColor: palette.white[8],
            },
          },

          "&.Mui-focused": {
            [`& fieldset`]: {
              borderWidth: 2,
              borderColor: palette.white[64],
            },
          },
        },
      },

      endAdornment: {
        right: "0!important",
      },
      paper: {
        backgroundColor: "rgba(39, 38, 43, 1)",
        borderRadius: 12,
        padding: "8px 12px",
      },
      popper: {
        padding: 0,
        marginTop: "8px",
        "&[data-focus='true']": {
          marginTop: "8px", // add margin when focused
        },
        //visibility: "hidden",
      },
      listbox: {
        padding: 0,
      },
      tag: {
        textTransform: "uppercase",
        border: "none",

        margin: 0,
        "&:first-of-type": {
          marginLeft: 0,
        },
      },
    },
  },

  MuiChip: {
    styleOverrides: {
      root: {
        height: 24,
        borderRadius: "12px",

        fontWeight: 500,
        fontSize: 11,
        color: palette.white[80],
        backgroundColor: palette.primary[32],
      },
      label: {
        paddingLeft: 8,
        paddingRight: 12,
        fontFamily: "Poppins, Montserrat",
      },
      deleteIcon: {
        fontSize: 16,
      },
    },
  },

  MuiSlider: {
    styleOverrides: {
      thumb: {
        background: palette.white.main,
        border: `1px solid ${palette.white[32]}`,
        boxShadow:
          "0px 2px 5px rgba(60, 66, 87, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.12)",

        "&:hover, &:focus": {
          boxShadow: "0px 0px 0px 5px rgba(112, 115, 231, 0.16)",
        },
      },
      rail: {
        background: palette.white[32],
      },
      mark: {
        color: palette.white[4],
      },
    },
  },

  // MuiMenu: {
  //   styleOverrides: {
  //     root: {
  //       // padding: 8,
  //       minWidth: "max-content",
  //       width: "fit-content",
  //     },
  //     papper: {
  //       background: palette.white[4],
  //     },
  //   },
  // },

  MuiDivider: {
    styleOverrides: {
      color: palette.white[16],
      textAlignLeft: {
        "&:before": { width: 0 },
        "&:after": { borderColor: palette.white[16] },
        ".MuiDivider-wrapper": {
          paddingLeft: 0,
          paddingRight: 16,
        },
      },
    },
  },

  MuiDialog: {
    styleOverrides: {
      container: {
        // background: "#14131a50",
        background: DIALOG_FULLSCREEN ? "transparent" : "#14131a50",
        paddingTop: DIALOG_FULLSCREEN ? 72 : 90,
        paddingBottom: DIALOG_FULLSCREEN ? 0 : 24,
      },
    },
  },

  MuiBackdrop: {
    styleOverrides: {
      root: {
        background: palette.black[16],
      },
    },
  },

  MuiAvatar: {
    styleOverrides: {
      root: {
        backgroundColor: palette.white[4],
      },
    },
  },

  MuiAppBar: {
    styleOverrides: {
      root: {
        zIndex: 99999999,
        height: 64,
        color: "#6C7072",
        background: palette.background?.default,
        borderBottom: `1px solid ${palette.white[4]}`,
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        padding: 12,
        // border: "1px solid #e0e0e0",
        backgroundImage: "none",
        borderRadius: 12,
      },
    },
  },
  MuiCardActionArea: {
    styleOverrides: {
      root: {
        borderRadius: 4,
      },
    },
  },
  MuiCardHeader: {
    styleOverrides: {
      root: {
        padding: 0,
        paddingTop: 4,
        ".MuiAvatar-root": {
          width: 26,
          height: 26,
        },
      },
      avatar: {
        marginRight: 12,
      },
      title: {
        // color: palette.white[64],
        //fontSize: 12,
        // fontFamily: "Poppins, Montserrat",
        // fontWeight: 400,
      },
      action: {
        margin: 0,
        display: "block",
      },
    },
  },

  MuiCardContent: {
    styleOverrides: {
      root: {
        padding: "16px 0",
        "&:last-child": {
          paddingBottom: 10,
        },
      },
    },
  },

  MuiPaper: {
    styleOverrides: {
      root: {
        width: "100%",
      },
    },
    variants: [
      {
        props: { elevation: 1 },
        style: {
          // boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
          boxShadow:
            "rgb(95 116 141 / 3%) 0px 2px 1px -1px, rgb(95 116 141 / 4%) 0px 1px 1px 0px, rgb(95 116 141 / 8%) 0px 1px 3px 0px",
        },
      },
      {
        props: { elevation: 3 },
        style: {
          boxShadow: "rgb(90 114 123 / 20%) 0px 7px 30px 0px",
        },
      },
      {
        props: { elevation: 6 },
        style: {
          boxShadow: "rgb(90 114 123 / 50%) 0px 10px 20px 0px",
        },
      },
    ],
  },

  MuiInputAdornment: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },

  MuiDigitalClock: {
    styleOverrides: {
      root: {
        position: "relative",
        paddingBottom: 190,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
      item: {
        color: palette.white[64],
      },
    },
  },

  MuiMultiSectionDigitalClock: {
    styleOverrides: {
      root: {
        border: "none",

        ".MuiMultiSectionDigitalClock-root": {
          border: "none!important",
          alignItems: "center",
          width: 70,
        },
      },
    },
  },

  MuiTimelineConnector: {
    styleOverrides: {
      root: {
        width: 2,
        backgroundColor: palette.white[16],
        marginTop: 4,
        marginBottom: 4,
      },
    },
  },

  MuiTimelineItem: {
    styleOverrides: {
      root: {
        "&::before": {
          content: "none",
        },
      },
    },
  },
  MuiTimelineContent: {
    styleOverrides: {
      root: {
        // paddingTop: 0,
        paddingBottom: 32,
      },
    },
  },

  MuiPopover: {
    styleOverrides: {
      root: {
        //  minWidth: 120,
        width: "fit-content",
      },
      paper: {
        borderRadius: 12,
        color: palette.white["80"],
        background: "#27262B50", // white 4
        backdropFilter: "blur(8px)",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        border: `1px solid ${palette.white[16]}`,
      },
    },
  },

  MuiPopper: {
    styleOverrides: {
      root: {
        borderRadius: 15,
        color: palette.white["80"],
        background: "rgba(26, 26, 26, 0.40)", // white 4
        backdropFilter: "blur(20px)",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        border: `1px solid ${palette.white[16]}`,
      },
    },
  },

  ...buttons,
  ...radio,
  ...checkbox,
  ...textfield,
  ...select,
  ...menu,
  ...tabs,
};

export default components;
