// eslint-disable-next-line import/no-extraneous-dependencies
import { API } from "@aws-amplify/api";
import { TSourceCodes } from "~/types/sources";
import { TRedditConnectionResponse } from "~/types/integrations/reddit";

const callbackUrl =
  process.env.REACT_APP_INTEGRATIONS_CALLBACK_URL ||
  "https://d1935y0mpjvqfn.cloudfront.net/callback:";

export const initRedditConnection = async (): Promise<{
  redirect_url: string;
}> =>
  API.post(TSourceCodes.REDDIT, "/connection/init", {
    body: {
      redirect_url: `${callbackUrl}/reddit/`,
    },
  });

export const completeRedditConnection = async ({
  authorization_code,
  redirect_url,
}: {
  authorization_code: string;
  redirect_url: string;
}): Promise<TRedditConnectionResponse> =>
  API.post(TSourceCodes.REDDIT, "/connection/complete", {
    body: { authorization_code, redirect_url },
  });

export const getRedditConnection =
  async (): Promise<TRedditConnectionResponse> =>
    API.get(TSourceCodes.REDDIT, "/connection", {});

export const deleteRedditConnection = async (): Promise<null> =>
  API.del(TSourceCodes.REDDIT, `/connection/`, {});
