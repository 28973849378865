// eslint-disable-next-line import/no-extraneous-dependencies
import { API } from "@aws-amplify/api";
import { TSchedule, TScheduleData } from "~/types/digest/schedule";
import { API_CONFIG } from "~/common/config/api";

// Schedule API Structure:
//
//     get: getScheduleAPICall,
//     create: createScheduleAPICall,
//     update: updateScheduleAPICall,

export const getScheduleAPICall = async (): Promise<TSchedule> =>
  API.get(API_CONFIG.schedule.resource, "", {});

export const createScheduleAPICall = async (
  data: TScheduleData
): Promise<TSchedule> =>
  API.post(API_CONFIG.schedule.resource, "/", {
    // @TODO handle timezone
    body: { ...data, schedule_timezone: "UTC" },
  });

export const updateScheduleAPICall = async (
  feedId: string,
  data: Partial<TScheduleData>
): Promise<TSchedule> =>
  API.patch(API_CONFIG.schedule.resource, `/${feedId}`, {
    body: data,
  });
