// import palette from "../../palette";
const checkbox = {
  MuiCheckbox: {
    defaultProps: {
      disableRipple: true,
    },

    styleOverrides: {
      root: {
        background: "transparent",
      },
    },
  },
};

export default checkbox;
