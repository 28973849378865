import React, { FC, ReactElement } from "react";
import DigestLayout from "~/layouts/DigestLayout";
import { ROUTES } from "~/common/config/router/routes";
import FlowLayout from "~/layouts/FlowLayout";
import { useLocation } from "react-router-dom";

type AuthorizedAreaProps = {
  children: ReactElement;
};

const AuthorizedArea: FC<AuthorizedAreaProps> = ({ children }) => {
  const location = useLocation();

  //  if (pathname === ROUTES.DIGEST)

  if (
    location.pathname.startsWith(ROUTES.FLOW_SETUP) ||
    location.pathname.startsWith(ROUTES.EMAIL)
  )
    return <FlowLayout>{children}</FlowLayout>;

  return <DigestLayout>{children}</DigestLayout>;

  // return <AuthorizedLayout>{children}</AuthorizedLayout>;
};

export default AuthorizedArea;
