// eslint-disable-next-line import/no-extraneous-dependencies
import { API } from "@aws-amplify/api";

import { API_CONFIG } from "~/common/config/api";
import { TProfile, TProfileData } from "~/types/profile";

export const getProfileAPICall = async (): Promise<TProfile> => {
  return API.get(API_CONFIG.profile.resource, "/profile", {});
};

export const updateProfileAPICall = async (
  data: Partial<TProfileData>
): Promise<TProfile> =>
  API.patch(API_CONFIG.profile.resource, "/profile", {
    body: data,
  });
