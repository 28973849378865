import palette from "~/common/mui/theme/palette";

const textfield = {
  MuiFormControl: {
    styleOverrides: {
      root: {
        borderRadius: 16,
      },
    },
    variants: [
      {
        props: { variant: "outlined", size: "medium" },
        style: {
          ".MuiInputBase-root": {
            //width: 335,
            background: palette.white[4],
            height: 48,
            fontSize: 14,
            fontWeight: 400,
            borderRadius: 16,
          },

          ".MuiInputBase-input": {
            padding: "13.5px 16px",

            "&.MuiFilledInput-input:-webkit-autofill": {
              borderRadius: 16,
            },
          },

          [`& fieldset`]: {
            borderColor: palette.white[4],
          },

          ".MuiInputAdornment-root": {
            color: "#8CA3BA",
          },
        },
      },
    ],
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        borderRadius: 16,
      },
    },

    variants: [
      {
        props: { variant: "standard" },
        style: {
          ".MuiInputBase-root": {
            "&:before, &:after": {
              border: "none",
            },
            "&:hover:not(.Mui-disabled):before": {
              border: "none",
            },
          },
        },
      },
      {
        props: { variant: "filled", size: "medium" },
        style: {
          ".MuiInputBase-root": {
            //width: 335,
            background: palette.white[4],
            height: 54, //64
            fontSize: 14,
            fontWeight: 400,
            borderRadius: 8,
          },

          ".MuiInputBase-input": {
            padding: "16px",

            "&.MuiFilledInput-input:-webkit-autofill": {
              borderRadius: 8,
              boxShadow: `0 0 0 100px #9482FF inset`,
            },
          },

          [`& fieldset`]: {
            borderColor: "#B2C9DC",
          },
          ".MuiInputAdornment-root": {
            color: "#8CA3BA",
          },
        },
      },

      {
        props: { size: "small" },
        style: {
          ".MuiInputBase-root": {
            height: 48,
            //width: 335,
            borderRadius: 16,
          },
          ".MuiInputBase-input": {
            borderRadius: 16,
            padding: "13.5px 16px",
          },
        },
      },
      {
        props: { variant: "outlined", size: "medium" },
        style: {
          ".MuiInputBase-root": {
            fontSize: 12,
            fontWeight: 600,
            borderRadius: 8,
            "&:hover fieldset": {
              borderColor: "#8CA3BA",
            },
          },

          ".MuiInputBase-input": {
            //  padding: "13.5px 14px",
          },

          [`& fieldset`]: {
            borderColor: "#B2C9DC",
          },
          ".MuiInputAdornment-root": {
            color: "#8CA3BA",
          },
        },
      },
    ],
  },

  MuiFormHelperText: {
    styleOverrides: {
      root: {
        marginLeft: 16,
        fontSize: "0.9rem",
        position: "absolute",
        bottom: -25,
        width: "100%",
      },
    },
  },

  MuiFormLabel: {
    styleOverrides: {
      root: {
        "&.MuiInputLabel-root": {
          paddingBottom: 1,
          fontWeight: 500,
          fontSize: 14,
          color: "inherit",
          textTransform: "none",
        },
      },
    },
  },
};

export default textfield;
