import { SvgIcon } from "@mui/material";
import React, { FC } from "react";
import palette from "~/common/mui/theme/palette";

type IArrowDownIcon = {
  fontSize?: number;
  fill?: string;
};

const ArrowDownIcon: FC<IArrowDownIcon> = ({ fontSize, fill }) => {
  return (
    <SvgIcon
      width="8"
      height="6"
      viewBox="0 0 8 6"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ fontSize: fontSize || 8, mr: 1.5 }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.234315 0.834351C0.546734 0.521932 1.05327 0.521932 1.36569 0.834351L4 3.46867L6.63432 0.834351C6.94673 0.521932 7.45327 0.521932 7.76569 0.834351C8.07811 1.14677 8.07811 1.6533 7.76569 1.96572L4.56569 5.16572C4.25327 5.47814 3.74673 5.47814 3.43432 5.16572L0.234315 1.96572C-0.0781049 1.6533 -0.0781049 1.14677 0.234315 0.834351Z"
        fill={fill || palette.white.main}
      />
    </SvgIcon>
  );
};

export default ArrowDownIcon;
