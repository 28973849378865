// eslint-disable-next-line import/no-extraneous-dependencies
import { API } from "@aws-amplify/api";
import { API_CONFIG } from "~/common/config/api";
import { TSummarize, TSummarizeData } from "~/types/summarize";

export const createSummarizeAPICall = async (
  data: TSummarizeData
): Promise<TSummarize> =>
  API.post(API_CONFIG.summarize.resource, "/summarize", {
    body: {
      ...data,
    },
  });
