import React, { FC } from "react";
import { Box } from "@mui/material";

const Step2Background: FC = () => {
  return (
    <Box
      sx={{
        position: "fixed",
        left: -50,
        top: "10%",
        filter: "blur(5px)",
        transform: "translateY(-10%)",
        transition: "all 0.5s ease-in-out",
      }}
    >
      <svg
        width="449"
        height="630"
        viewBox="0 0 449 630"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.6" filter="url(#filter0_f_1082_13888)">
          <ellipse
            cx="29.5"
            cy="305"
            rx="187.5"
            ry="93"
            fill="url(#paint0_radial_1082_13888)"
          />
        </g>
        <defs>
          <filter
            id="filter0_f_1082_13888"
            x="-389.92"
            y="-19.9202"
            width="838.84"
            height="649.84"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="115.96"
              result="effect1_foregroundBlur_1082_13888"
            />
          </filter>
          <radialGradient
            id="paint0_radial_1082_13888"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(-42.6154 216.292) rotate(57.4989) scale(212.058 334.47)"
          >
            <stop stopColor="#E1C0FF" />
            <stop offset="0.486726" stopColor="#9282FF" />
            <stop offset="0.728982" stopColor="#9D6FFF" />
            <stop offset="1" stopColor="#EB73FF" />
          </radialGradient>
        </defs>
      </svg>
    </Box>
  );
};

export default Step2Background;
