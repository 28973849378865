// eslint-disable-next-line import/no-extraneous-dependencies
import { API } from "@aws-amplify/api";
import { API_CONFIG } from "~/common/config/api";
import { UseMutationResult } from "@tanstack/react-query";
// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuidv4 } from "uuid";
import { TDigest, TDigestJSON } from "~/types/v0.1/digest";

export const getDigestAPICall = async (digestId?: string): Promise<TDigest> => {
  if (!!digestId) throw new Error("Digest ID is required");
  return API.get(API_CONFIG.digest.resource, `/${digestId}`, {});
};

export const getDigestListAPICall = async (): Promise<TDigest[]> =>
  API.get(API_CONFIG.digest.resource, "", {});

export const generateDigestAPICall = async (
  topicId?: string
): Promise<
  UseMutationResult<
    { digest_id: string; feed_id: string; uri: string },
    unknown,
    any
  >
> => {
  if (!topicId) throw new Error("Topic ID is required");
  return API.post(API_CONFIG.digest.resource, "/generate", {
    body: {
      feed_id: topicId,
      digest_id: uuidv4(),
    },
  });
};

export const fetchDigestAPICall = async (
  uri?: string
): Promise<TDigestJSON | null> => {
  if (!uri) return null;
  return fetch(uri, {
    mode: "cors",
    method: "GET",
    headers: { Accept: "application/json" },
  }).then((r) => r.json());
};
