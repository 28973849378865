import { Box, Typography } from "@mui/material";
import WLogo from "~/components/shared/Logo/WLogo";
// import { Link } from "react-router-dom";
// import { ROUTES } from "~/common/config/router/routes";
import React from "react";

const logoContainerStyles = {
  flexGrow: 1,
  gap: { xs: 0, sm: 1 },
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  textDecoration: "none",
};

const Logo = () => {
  return (
    <Box sx={logoContainerStyles}>
      <WLogo />
      <Typography fontWeight={500} fontFamily="Poppins">
        Wisefeed
      </Typography>
    </Box>
  );
};

export default Logo;
