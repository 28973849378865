import { TRss } from "~/types/sources/rss";
import { TGmail } from "~/types/sources/gmail";
import { TReddit } from "~/types/sources/reddit";
import { TTwitter } from "~/types/sources/twitter";

// ======================================================================

export const TSourceCodes: { [key: string]: string } = {
  YOUTUBE: "youtube",
  TWITTER: "twitter",
  GMAIL: "gmail",
  RSS: "rss",
  REDDIT: "reddit",
} as const;

// ======================================================================

export type TSource<SourceType> = {
  type: "source";
  created_at: string;
  data: SourceType;
  feed_id: string;
  is_active: boolean;
  last_updated_at: string;
  meta: any;
  uniq_key: string;
  user_id: string;
  uuid: string;
  keywords: string[];
  posts_limit: number;
};

export type TSourceType = TRss | TGmail | TReddit | TTwitter;
