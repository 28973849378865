import { useEffect, useState } from "react";
import {
  useDeleteIntegrationConnection,
  useGetIntegrationConnection,
  useInitIntegrationConnection,
} from "~/hooks/app/integrations";
import { TGmailConnectionResponse } from "~/types/integrations/gmail";
import { toast } from "react-toastify";

// Data layer hook for Gmail integration UI 📡

const useGmailIntegration = (): {
  connectionData: TGmailConnectionResponse | null | undefined;
  redirect_url: string | undefined;
  handleInit: () => void;
  handleLogout: () => void;
  isLoading: boolean;
} => {
  // connection
  const {
    data,
    refetch,
    isFetching: isDataLoading,
  } = useGetIntegrationConnection("gmail") || {};
  const connectionData = data as TGmailConnectionResponse | null | undefined;

  // init
  const [callFlag, setCallFlag] = useState(false);
  const { data: integrationData, isFetching: isInitLoading } =
    useInitIntegrationConnection("gmail", callFlag) || {};
  const { redirect_url } = integrationData || {};

  // delete
  const deleteConnection = useDeleteIntegrationConnection();

  // handlers
  const handleInit = () => setCallFlag(true);
  const handleLogout = async () => {
    await deleteConnection
      .mutateAsync({ type: "gmail" })
      .then(() => refetch())
      .finally(() => {
        deleteConnection.mutate({ type: "gmail" });
        toast.success("Integration removed");
      });
  };

  // redirect after init
  useEffect(() => {
    if (callFlag && redirect_url) {
      window.open(redirect_url);
    }
  }, [callFlag, integrationData]);

  return {
    handleInit,
    handleLogout,
    redirect_url,
    connectionData,
    isLoading: isDataLoading || isInitLoading,
  };
};

export default useGmailIntegration;
