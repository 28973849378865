import palette from "../../palette";

const radio = {
  MuiRadio: {
    defaultProps: {
      disableRipple: true,
    },

    styleOverrides: {
      root: {
        height: 20,
        width: 20,
        backgroundColor: "white",
        border: `1px solid ${palette.black[16]}`,
        marginRight: 6,

        "&:hover": {
          borderColor: palette.primary.main,
        },

        "&.Mui-checked": {
          "&:hover": {
            color: palette.primary[64],
          },
        },

        "&.Mui-disabled": {
          color: "transparent",
          backgroundColor: palette.lightGray[80],
          borderColor: palette.lightGray.main,

          "&.Mui-checked": {
            color: palette.white.main,
          },
        },

        "& .MuiSvgIcon-root:first-child": {
          visibility: "hidden",
        },
        "& .MuiSvgIcon-root:last-child": {
          transform: "scale(1.3)",
        },
      },
    },
  },

  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        marginLeft: 0,
        marginRight: 12,
      },
    },
  },

  MuiRadioGroup: {
    styleOverrides: {},
  },
};

export default radio;
