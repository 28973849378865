import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "@tanstack/react-query";
import { unitAPIv2 } from "~/api";
import { TSchedule, TScheduleData } from "~/types/digest/schedule";
import { toast } from "react-toastify";
import { API_CONFIG } from "~/common/config/api";

// useGetSchedule: getScheduleAPICall,
// useCreateSchedule: createScheduleAPICall,
// useUpdateSchedule: updateScheduleAPICall,

export const useGetSchedule = (): UseQueryResult<TSchedule, Error> =>
  useQuery({
    queryKey: [API_CONFIG.schedule.queryItemKey],
    queryFn: () => unitAPIv2.digest.feeds.getSchedule(),
    retry: false,
    // placeholderData: () => {
    //   return initialSchedule;
    // },
    onError: () => {
      return null;
    },
  });

export const useCreateSchedule = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: TScheduleData) => {
      return unitAPIv2.digest.feeds.createSchedule(data);
    },
    onSuccess: async () => {
      toast.success(API_CONFIG.schedule.messages.success.update);
      await queryClient.invalidateQueries({
        queryKey: [API_CONFIG.schedule.queryItemKey],
      });
    },
  });
};

export const useUpdateSchedule = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      feedId,
      data,
    }: {
      feedId: string;
      data: Partial<TScheduleData>;
    }) => unitAPIv2.digest.feeds.updateSchedule(feedId, data),
    onSuccess: async () => {
      toast.success(API_CONFIG.schedule.messages.success.update);
      await queryClient.invalidateQueries({
        queryKey: [API_CONFIG.feeds.queryListKey],
      });
    },
  });
};
