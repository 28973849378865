import React, { FC, useState } from "react";
import { Box, Container, Stack, Toolbar, Typography } from "@mui/material";
import HugeSwitch from "~/components/GuestScreen/HugeSwitch";
import palette from "~/common/mui/theme/palette";
import ShinyButton from "~/components/shared/Shiny/Button";
import GuestScreenHeader from "~/components/GuestScreen/Header";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "~/common/config/router/routes";
import HomeBackground from "~/common/assets/backgrounds/homeBackground";

const GuestScreen: FC = () => {
  const navigate = useNavigate();
  const [toggled, setToggled] = useState(true);
  const handleToggle = (value?: boolean) => {
    setToggled((prev) => value || !prev);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <GuestScreenHeader />
      </Box>

      <HomeBackground />

      <Box component="main">
        <Toolbar />

        <Container
          maxWidth="appbar"
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            zIndex: 1,
            position: "relative",
            height: "calc(100vh - 120px)",
          }}
        >
          <Stack
            sx={{
              gap: 2,
              maxWidth: 700,
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            <Box
              sx={{
                fontSize: 52,
                fontWeight: 400,
                fontFamily: "Playfair Display",
              }}
            >
              Empower your productivity with filtered news
              <Box sx={{ display: "inline-flex", pl: 2 }}>
                <HugeSwitch
                  defaultChecked={toggled}
                  onChange={() => handleToggle()}
                />
              </Box>
            </Box>

            <Typography
              sx={{
                fontSize: 22,
                fontWeight: 400,
                fontFamily: "Poppins",
                color: palette.white[80],
              }}
            >
              Stay informed and focused on what matters most
            </Typography>

            <Box sx={{ pt: 8, display: "flex", alignItems: "center", gap: 2 }}>
              <ShinyButton
                disabled={!toggled}
                onClick={() => navigate(ROUTES.SIGNUP)}
              >
                <Typography
                  sx={{
                    fontSize: 18,
                    fontWeight: 500,
                    fontFamily: "Poppins",
                    zIndex: 999,
                    color: palette.white.main,
                  }}
                >
                  Sign up
                </Typography>
              </ShinyButton>

              <ShinyButton
                disabled={!toggled}
                onClick={() => navigate(ROUTES.LOGIN)}
                background={`linear-gradient(145deg, #ffffff 0%, #9b5de5 0%, #9c26b1e0 50%, #98005b 100%)`}
              >
                <Typography
                  sx={{
                    fontSize: 18,
                    fontWeight: 500,
                    fontFamily: "Poppins",
                    zIndex: 999,
                    color: palette.white.main,
                  }}
                >
                  Log In
                </Typography>
              </ShinyButton>
            </Box>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
};

export default GuestScreen;
