// eslint-disable-next-line import/no-extraneous-dependencies
import { API } from "@aws-amplify/api";
import { TSourceCodes } from "~/types/sources";
import { TGmailSearchData } from "~/types/sources/gmail";

export const searchGmailItemAPICall = async ({
  sender,
}: {
  sender?: string;
  subject?: string;
}): Promise<TGmailSearchData[]> => {
  return API.get(TSourceCodes.GMAIL, `/search/?sender=${sender}`, {});
};

export const createGmailItemAPICall = async (
  data: TGmailSearchData
): Promise<any> =>
  API.post(TSourceCodes.GMAIL, "/add-source/", {
    body: data,
  });
