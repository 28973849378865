import paletteUI from "../../config/paletteUI";
import { PaletteOptions } from "@mui/material";

const palette: PaletteOptions & {
  white: {
    4: string;
    8: string;
    16: string;
    32: string;
    64: string;
    80: string;
    main: string;
  };
  lightGray: {
    4: string;
    16: string;
    32: string;
    64: string;
    80: string;
    main: string;
  };
  darkGray: {
    4: string;
    16: string;
    32: string;
    64: string;
    80: string;
    main: string;
  };
  black: {
    4: string;
    16: string;
    32: string;
    64: string;
    80: string;
    main: string;
  };
  primary: {
    4: string;
    16: string;
    32: string;
    64: string;
    80: string;
    main: string;
    dark?: string;
    contrastText?: string;
  };
  secondary: {
    main: string;
    dark?: string;
    contrastText?: string;
  };
} = {
  ...paletteUI.base,
  primary: { ...paletteUI.accent, dark: paletteUI.accent[80] },
  secondary: {
    main: paletteUI.base.white[16],
    dark: paletteUI.base.white[32],
    contrastText: paletteUI.base.white.main,
  },
  success: paletteUI.indicators.green,
  info: paletteUI.accent,
  error: paletteUI.indicators.red,
  warning: paletteUI.indicators.yellow,

  background: {
    default: paletteUI.background,
    paper: "rgba(255, 255, 255, 0.04)",
  },
  mode: "dark",
};

export default palette;
