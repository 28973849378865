import React, { FC } from "react";
import { styled, Switch, SwitchProps } from "@mui/material";
import palette from "~/common/mui/theme/palette";

type CustomSwitchProps = SwitchProps;

const SwitchStyled = styled(Switch)(({ theme }) => ({
  width: 45,
  height: 21,
  borderRadius: 10.5,

  padding: 0,
  display: "flex",

  "&:before": {
    content: '"1"',
    position: "absolute",
    fontSize: 12,
    borderRadius: "50%",
    left: 9,
    top: 2,
  },

  "&:after": {
    content: '"3"',
    position: "absolute",
    fontSize: 12,
    borderRadius: "50%",
    right: 9,
    top: 2,
  },
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 16,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(23px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: "2.5px 3px",

    "&:not(.Mui-checked) .MuiSwitch-thumb:after": {
      content: "'1'",
    },

    "&.Mui-checked": {
      transform: "translateX(23px)",
      color: "#fff",

      ".MuiSwitch-thumb:after": {
        content: "'3'",
      },

      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor:
          theme.palette.mode === "dark"
            ? "#177ddc"
            : theme.palette.success.main,
      },
      "&.Mui-disabled": {
        color: palette.white[64],
        "& + .MuiSwitch-track": {
          backgroundColor: palette.white[8],
        },
      },
    },
  },

  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
  ".Mui-disabled+.MuiSwitch-track": {
    opacity: 0.5,
  },

  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 16,
    height: 16,
    borderRadius: 8,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
    "&:after": {
      content: "''",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      color: "black",
      fontSize: 12,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
}));

const GridSwitch: FC<CustomSwitchProps> = (props) => {
  return <SwitchStyled {...props} />;
};

export default GridSwitch;
