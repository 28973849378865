import styled from "@emotion/styled";
import { Box, LinearProgress, linearProgressClasses } from "@mui/material";
import { FC, useEffect, useState } from "react";

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 4,
  borderRadius: 2,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "transparent",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 2,
    backgroundColor: "transparent",
    transitionDuration: ".2s",
    backgroundImage:
      "linear-gradient(90deg, rgba(225, 192, 255, 0.80) -22%, rgba(146, 130, 255, 0.80) 18%, rgba(157, 111, 255, 0.80) 80%, rgba(235, 115, 255, 0.80) 100%);",
  },
}));

const ProgressBar: FC = () => {
  const [progress, setProgress] = useState(0);

  const handleScroll = () => {
    const winScroll = document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = Math.round((winScroll / height) * 100);
    setProgress(scrolled);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Box>
      <BorderLinearProgress variant="determinate" value={progress} />
    </Box>
  );
};

export default ProgressBar;
