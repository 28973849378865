import { FC, Fragment } from "react";
import { Box, Button } from "@mui/material";
import palette from "~/common/mui/theme/palette";
import GmailIcon from "~/common/assets/icons/source/GmailIcon";
import useGmailIntegration from "~/services/integrations/Gmail";
import LogoutIcon from "~/common/assets/icons/system/LogoutIcon";
import { motion } from "framer-motion";

const SettingsGmailAccount: FC = () => {
  const { handleInit, handleLogout, connectionData } = useGmailIntegration(); // Data layer
  const warningAnimation = {
    initial: { opacity: 0.3 },
    animate: { opacity: 0.8 },
    exit: { opacity: 0.3 },
  };

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          fontWeight: 500,
          wordBreak: "break-word",
        }}
      >
        <GmailIcon fontSize={18} />
        {!!connectionData ? (
          connectionData.data.email.replace(/@.*$/, "")
        ) : (
          <>
            <span>Gmail account</span>
            <motion.span
              {...warningAnimation}
              transition={{
                duration: 1,
                delay: 1,
                repeat: Infinity,
                repeatType: "reverse",
              }}
            >
              ⚠️
            </motion.span>
          </>
        )}
      </Box>
      {!!connectionData ? (
        <Button
          onClick={handleLogout}
          size="small"
          variant="contained"
          color="secondary"
          sx={{
            color: palette.white[64],
            fontWeight: 500,
            display: "flex",
            gap: 1,

            flexShrink: 0,
          }}
        >
          Disconnect
          <LogoutIcon fontSize={12} />
        </Button>
      ) : (
        <Button
          onClick={handleInit}
          size="small"
          variant="contained"
          color="secondary"
          sx={{ background: palette.white[16], fontWeight: 500 }}
        >
          + Connect
        </Button>
      )}
    </Fragment>
  );
};

export default SettingsGmailAccount;
