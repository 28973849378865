import palette from "../../palette";

const focusColor = palette.white[64];

const buttons = {
  MuiButton: {
    defaultProps: {
      disableRipple: true,
      disableElevation: true,
    },
    styleOverrides: {
      root: {
        display: "flex",
        position: "relative",
        justifyContent: "center",
        textTransform: "none",
        fontWeight: 500,
        fontFamily: "Poppins",

        "&.Mui-disabled .MuiSvgIcon-root": {
          color: "inherit",
        },

        "&.Mui-disabled": {
          path: {
            fill: palette.white[32],
          },
        },

        "&:focus": {
          outline: `2px solid ${focusColor}`,
        },
      },

      startIcon: {
        position: "absolute",
        left: 14,
      },
      endIcon: {
        position: "absolute",
        right: 14,
      },

      iconSizeSmall: {
        "& > *:first-of-type": {
          fontSize: 13,
          width: 13,
          height: 13,
          color: "white",
        },
      },
      iconSizeMedium: {
        "& > *:first-of-type": {
          fontSize: 20,
          color: "white",
        },
      },
      iconSizeLarge: {
        "& > *:first-of-type": {
          fontSize: 24,
          color: "white",
        },
      },
    },
    variants: [
      {
        props: { size: "small" },
        style: {
          fontSize: 11,
          padding: "3.5px 8px",
          height: 24,
          minWidth: 55,
          width: "fit-content",
          borderRadius: 12,
          lineHeight: "18px",
          fontWeight: 400,

          ".MuiButton-startIcon": {
            position: "relative",
            marginRight: 8,
            marginLeft: 0,
            left: 0,
          },

          ".MuiButton-endIcon": {
            position: "relative",
            marginLeft: 8,
            marginRight: 0,
            right: 0,
          },
        },
      },
      {
        props: { size: "medium" },
        style: {
          fontSize: 14,
          minWidth: 160,
          padding: "8px 8.5px",
          borderRadius: 16,
        },
      },
      {
        props: { size: "large" },
        style: {
          fontSize: 16,
          minWidth: 210,
          minHeight: 54,
          padding: "12px",
          borderRadius: 20,

          ".MuiButton-startIcon": {
            marginRight: 16,
          },

          ".MuiButton-endIcon": {
            marginLeft: 16,
          },
        },
      },

      {
        props: { variant: "contained", color: "primary" },
        style: {
          "&.Mui-disabled": {
            backgroundColor: palette.primary[32],
            color: palette.white[32],
          },
        },
      },
      {
        props: { variant: "contained", color: "secondary" },
        style: {
          "&.Mui-disabled": {
            backgroundColor: palette.white[4],
            color: palette.white[32],
          },
        },
      },

      {
        props: { variant: "outlined", color: "secondary" },
        style: {
          border: `2px solid ${palette.white[16]}`,
          color: palette.white.main,

          "&:hover": {
            borderWidth: 2,
            borderColor: palette.white[32],
            background: "transparent",
          },

          "&:focus": {
            outline: "none",
            border: `2px solid ${focusColor}`,
          },

          "&.Mui-disabled": {
            borderColor: palette.white[4],
            color: palette.white[32],
          },
        },
      },

      {
        props: { variant: "text", color: "primary" },
        style: {
          "&:hover": {
            color: palette.primary[80],
            backgroundColor: "transparent",

            ".MuiSvgIcon-root": {
              color: palette.white[80],
            },
          },

          "&.Mui-disabled": {
            color: palette.primary[64],
          },
        },
      },

      {
        props: { variant: "text", color: "secondary" },
        style: {
          "&:hover": {
            color: palette.primary[80],
            backgroundColor: "transparent",
          },

          "&.Mui-disabled": {
            color: palette.primary[64],
          },
        },
      },
    ],
  }, // MuiButton

  MuiButtonBase: {
    styleOverrides: {
      root: {
        "&-MuiTab-root.MuiButtonBase-root": {
          color: palette.white.main,
        },
      },
    },
  },
  MuiLoadingButton: {
    variants: [
      {
        props: { variant: "outlined", color: "secondary" },
        style: {
          border: `2px solid ${palette.white[16]}`,
          "&.Mui-disabled": {
            border: `2px solid ${palette.white[16]}`,
          },
        },
      },
      {
        props: { variant: "contained", color: "primary" },
        style: {
          "&.Mui-disabled": {
            backgroundColor: palette.primary[80],
          },
        },
      },
      {
        props: { variant: "contained", color: "secondary" },
        style: {
          "&.Mui-disabled": {
            backgroundColor: palette.white[16],
          },
        },
      },

      {
        props: { size: "large" },
        style: {
          padding: 0,
        },
      },
      {
        props: { size: "small" },
        style: {
          width: 55,
        },
      },
    ],
  }, // MuiLoadingButton

  MuiIconButton: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      path: {
        fill: "inherit",
      },
      ".MuiSvgIcon-root": {
        color: "white",

        "&:focus": {
          svg: {
            path: { fill: palette.white.main, color: palette.white.main },
          },
        },
      },
    },
    variants: [
      {
        props: { variant: "contained", color: "primary" },
        style: {
          backgroundColor: palette.primary.main,
        },
      },
      {
        props: { variant: "contained", color: "secondary" },
        style: {
          backgroundColor: palette.secondary.main,
          color: palette.secondary.contrastText,

          "&.Mui-disabled": {
            backgroundColor: palette.white[4],
            color: palette.white[80],
          },

          "&:hover": {
            backgroundColor: palette.white[32],
          },
        },
      },
      {
        props: { variant: "outlined", color: "secondary" },
        style: {
          border: `2px solid ${palette.white[16]}`,
          backgroundColor: "none",
          "&.Mui-disabled": {
            border: `2px solid ${palette.white[16]}`,
          },
          "&:hover": {
            borderColor: palette.white[32],
          },
        },
      },
      {
        props: { color: "primary" },
        style: {
          backgroundColor: palette.primary.main,
          color: palette.primary.contrastText,

          "&:hover": {
            backgroundColor: palette.primary[80],
          },

          "&.Mui-disabled": {
            backgroundColor: palette.primary[64],
            color: palette.white[80],
          },
        },
      },

      {
        props: { size: "large" },
        style: {
          fontSize: 22,
          padding: 12,
          width: 48,
          height: 48,
          borderRadius: 20,
        },
      },
      {
        props: { size: "small" },
        style: {
          fontSize: 22,
          padding: 0,
          width: 48,
          height: 24,
          borderRadius: 12,

          svg: {
            width: 13,
            height: 13,
          },
        },
      },
      {
        props: { variant: "text" },
        style: {
          fontSize: 22,
          padding: 12,
          width: 48,
          height: 48,
          borderRadius: 20,

          "&:hover": {
            background: "transparent",
            path: {
              fill: palette.white[80],
            },
          },
        },
      },
      {
        props: { variant: "text", size: "small" },
        style: {
          width: 48,
          height: 24,
          "&:focus": {
            border: "none",
            outline: `2px solid ${focusColor}`,
          },
        },
      },
    ],
  }, // MuiIconButton
};

export default buttons;
