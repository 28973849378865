import palette from "~/common/mui/theme/palette";

export default {
  MuiTabs: {
    styleOverrides: {
      root: {
        display: "flex",
        minHeight: 30,

        ".MuiTabs-indicator": {
          background: "transparent",
        },
        ".MuiTabs-flexContainer": {
          gap: [0, 10],
        },
      },
    },
  },

  MuiTab: {
    styleOverrides: {
      root: {
        "&.MuiButtonBase-root": {
          padding: "8px 16px",
          borderRadius: "20px",
          minWidth: 16,
          minHeight: 32,
          display: "flex",
          gap: 4,

          color: palette.white[64],

          fontFamily: "Poppins",
          fontSize: 14,
          fontWeight: 500,
          textTransform: "none",

          "&.Mui-selected": {
            background: palette.white[16],
            color: palette.white.main,
          },
          "&.Mui-disabled": {
            opacity: 0.3,
          },
        },
      },

      labelIcon: {
        color: palette.white["64"],
      },
    },
    defaultProps: { disableRipple: true },
  },

  MuiTabPanel: {
    styleOverrides: {
      root: {
        "&.MuiTabPanel-root": {
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: "0",
        },
      },
    },
  },
};
