import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "@tanstack/react-query";
import { unitAPIv2 } from "~/api";
import { API_CONFIG } from "~/common/config/api";
import { TProfile, TProfileData } from "~/types/profile";

export const useGetProfile = (): UseQueryResult<TProfile, Error> =>
  useQuery({
    queryKey: [API_CONFIG.profile.queryItemKey],
    queryFn: () => unitAPIv2.profile.get(),
    retry: false,
    onError: () => {
      return null;
    },
  });

export const useUpdateProfile = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Partial<TProfileData>) => unitAPIv2.profile.put(data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [API_CONFIG.profile.queryItemKey],
      });
    },
  });
};
