const paletteUI = {
  background: "#14131A",
  base: {
    white: {
      4: "rgba(256, 256, 256, .04)",
      8: "rgba(256, 256, 256, .08)",
      16: "rgba(256, 256, 256, .16)",
      32: "rgba(256, 256, 256, .32)",
      64: "rgba(256, 256, 256, .64)",
      80: "rgba(256, 256, 256, .8)",
      main: "rgba(256, 256, 256, 1)",
    },
    lightGray: {
      4: "rgba(247, 248, 249, 0.04)",
      16: "rgba(247, 248, 249, 0.16)",
      32: "rgba(247, 248, 249, 0.32)",
      64: "rgba(247, 248, 249, 0.64)",
      80: "rgba(247, 248, 249, 0.8)",
      main: "rgba(247, 248, 249, 1)",
    },
    darkGray: {
      4: "rgba(33, 34, 49, .04)",
      16: "rgba(33, 34, 49, .16)",
      32: "rgba(33, 34, 49, .32)",
      64: "rgba(33, 34, 49, .64)",
      80: "rgba(33, 34, 49, .8)",
      main: "rgba(33, 34, 49, 1)",
    },
    black: {
      4: "rgba(0, 0, 0, .04)",
      16: "rgba(0, 0, 0, .16)",
      32: "rgba(0, 0, 0, .32)",
      64: "rgba(0, 0, 0, .64)",
      80: "rgba(0, 0, 0, .8)",
      main: "rgba(0, 0, 0, 1)",
    },
  },
  accent: {
    4: "rgba(112, 115, 231, 0.04)",
    16: "rgba(112, 115, 231, 0.16)",
    32: "rgba(112, 115, 231, 0.32)",
    64: "rgba(112, 115, 231, 0.64)",
    80: "rgba(112, 115, 231, 0.8)",
    main: "rgba(112, 115, 231, 1)",
    contrastText: "#ffffff",
  },
  indicators: {
    green: {
      4: "rgba(135, 197, 63, 0.04)",
      16: "rgba(135, 197, 63, 0.16)",
      32: "rgba(135, 197, 63, 0.32)",
      64: "rgba(135, 197, 63, 0.64)",
      80: "rgba(135, 197, 63, 0.8)",
      main: "rgba(135, 197, 63, 1)",
    },
    red: {
      4: "rgba(230, 64, 53, 0.04)",
      16: "rgba(230, 64, 53, 0.16)",
      32: "rgba(230, 64, 53, 0.32)",
      64: "rgba(230, 64, 53, 0.64)",
      80: "rgba(230, 64, 53, 0.8)",
      main: "rgba(230, 64, 53, 1)",
    },
    yellow: {
      4: "rgba(252, 209, 56, 0.04)",
      16: "rgba(252, 209, 56, 0.16)",
      32: "rgba(252, 209, 56, 0.32)",
      64: "rgba(252, 209, 56, 0.64)",
      80: "rgba(252, 209, 56, 0.8)",
      main: "rgba(252, 209, 56, 1)",
    },
  },
};

export default paletteUI;
