import React, { FC, ReactElement } from "react";
import GuestLayout from "~/layouts/GuestLayout";
import { useLocation } from "react-router-dom";
import GuestScreen from "~/pages/HomeScreen";

type AuthorizedAreaProps = {
  children: ReactElement;
};

const AuthorizedArea: FC<AuthorizedAreaProps> = ({ children }) => {
  const location = useLocation();

  if (location.pathname === "/") return <GuestScreen />;

  return <GuestLayout>{children}</GuestLayout>;
};

export default AuthorizedArea;
