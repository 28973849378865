import React, { FC, useEffect, useRef } from "react";
import { Box, Button, ButtonProps } from "@mui/material";
import palette from "~/common/mui/theme/palette";
import { styled } from "@mui/material/styles";
import { genKey } from "~/utils/genKey";

type IShinyButton = ButtonProps & {
  children: any;
  disabled?: boolean;
  onClick?: () => void;
  onBlur?: () => void;
  fullWidth?: boolean;
  background?: string;
  buttonProps?: ButtonProps;
  borderRadius?: number;
  sx?: ButtonProps["sx"];
  shinyStyles?: ButtonProps["sx"];
};

const ShinyWrapper = styled(Box)(
  ({
    disabled,
    fullWidth,
    borderRadius,
    background,
    shinystyles,
  }: {
    disabled?: boolean;
    fullWidth?: boolean;
    borderRadius?: number;
    background?: string;
    shinystyles?: ButtonProps["sx"];
  }) => ({
    padding: 4,
    position: "relative",
    borderRadius: borderRadius || "20px",
    overflow: "hidden",
    height: 62,
    width: fullWidth ? "100%" : 220,

    ".shine__button-effect": {
      content: "''",
      position: "absolute",
      width: 210,
      height: 210,
      borderRadius: "50%",
      background: disabled ? "transparent" : "rgba(255,255,255,0.5)",
      opacity: 0,
      zIndex: 99,
      filter: "blur(20px)",
      ...shinystyles,
    },

    ".shine__button-inner": {
      transition: "all 300ms ease-in-out",
      backdropFilter: "blur(80px)",
      height: "100%",
      borderRadius: borderRadius ? borderRadius - 2 : "18px",
      width: "100%",
      padding: "12px 15px",
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: 8,
      color: palette.white.main,
      background:
        background ||
        "radial-gradient(154.79% 150.04% at 3.69% 7.41%, #E1C0FF 0%, #9282FF 39.15%, #A87EFF 74.85%, #EB73FF 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,\n" +
          "linear-gradient(93.13deg, rgba(255, 255, 255, 0.62) 2.3%, rgba(255, 255, 255, 0.01) 48.36%, rgba(255, 255, 255, 0.79) 97.31%)",
      fontWeight: 500,
      opacity: disabled ? 0.6 : 1,
      cursor: disabled ? "not-allowed" : "pointer",
      fontSize: 18,
      fontFamily: "Poppins",
      "&:hover": {
        color: palette.white.main,
      },
    },

    "&:hover": {
      ".shine-effect": {
        opacity: 1,
        transition: "opacity 0.3s ease-in-out",
      },
    },
  })
);

const ShinyButton: FC<IShinyButton> = ({
  children,
  disabled,
  onClick,
  onBlur,
  fullWidth,
  borderRadius,
  buttonProps,
  background,
  sx,
  shinyStyles,
  ...props
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const beforeElRef = useRef<HTMLDivElement>(null);

  function handleMouseMove(event: any): void {
    const mouseX = event.clientX;
    const mouseY = event.clientY;

    const wrapperEl = wrapperRef.current;
    const wrapperElRect = wrapperEl?.getBoundingClientRect();
    if (!wrapperElRect) return;

    const {
      x,
      y,
      // width,
      // height
    } = wrapperElRect;
    const beforeEl = beforeElRef.current;
    if (!beforeEl) return;

    const beforeElRect = beforeEl.getBoundingClientRect();
    const beforeElWidth = beforeElRect.width;
    const beforeElHeight = beforeElRect.height;

    const offsetX = beforeElWidth / 2;
    const offsetY = beforeElHeight / 2;

    beforeEl.style.top = `${mouseY - y - offsetY}px`;
    beforeEl.style.left = `${mouseX - x - offsetX}px`;
    beforeEl.style.opacity = "0.4";
  }

  function handleMouseLeave(): void {
    const beforeEl = beforeElRef.current;
    if (beforeEl) {
      beforeEl.style.opacity = "0";
      const wrapperEls = Array.from(
        wrapperRef.current?.parentElement?.children || []
      );
      wrapperEls.forEach((c: any) => {
        const shine = c.querySelector(".beforeEl") as HTMLElement;
        if (!shine?.style) return;
        shine.style.opacity = "0";
      });
    }
  }

  useEffect(() => {
    const wrapperEl = wrapperRef.current;
    const beforeEl = beforeElRef.current;
    if (wrapperEl && beforeEl) {
      const buttonEl = wrapperEl.querySelector(".shine__button-inner");
      if (buttonEl) {
        buttonEl.addEventListener("mouseenter", handleMouseMove);
        buttonEl.addEventListener("mouseleave", handleMouseLeave);
      }
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseleave", handleMouseLeave);
    }
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseleave", handleMouseLeave);
      const buttonEl = wrapperEl?.querySelector(".shine__button-inner");
      if (buttonEl) {
        buttonEl.removeEventListener("mouseenter", handleMouseMove);
        buttonEl.removeEventListener("mouseleave", handleMouseLeave);
      }
    };
  }, []);

  return (
    <ShinyWrapper
      ref={wrapperRef}
      key={genKey()}
      disabled={disabled}
      borderRadius={borderRadius}
      background={background}
      sx={{ ...sx, width: fullWidth ? "100%" : 220 }}
      shinystyles={shinyStyles}
    >
      <Box
        ref={beforeElRef}
        className="shine__button-effect"
        style={{ position: "absolute", pointerEvents: "none" }}
      />

      <Button
        type="submit"
        className="shine__button-inner"
        disabled={disabled}
        onClick={onClick}
        fullWidth={fullWidth}
        sx={{
          ...buttonProps?.sx,
        }}
        {...props}
        {...buttonProps}
      >
        {children}
      </Button>
    </ShinyWrapper>
  );
};

export default ShinyButton;
