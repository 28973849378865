import palette from "~/common/mui/theme/palette";

export default {
  MuiMenu: {
    defaultProps: {
      disableAutoFocusItem: true,
    },
    // styleOverrides: {
    //   root: {
    //     width: <- need to be set in component
    //   },
    // },
  },

  MuiMenuItem: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        color: palette.white[64],

        padding: 6,
        paddingLeft: 12,

        borderRadius: 8,

        fontSize: 14,
        fontWeight: 500,
        fontFamily: "Poppins",
        whiteSpace: "inherit",

        display: "flex",
        gap: 11,
        justifyContent: "space-between",

        "&.Mui-Disabled": {
          backgroundColor: "transparent",
        },

        "&.Mui-selected": {
          backgroundColor: palette.primary[32],
          color: palette.white.main,
        },

        "@media (min-width: 576px)": {
          minHeight: 38,
        },
      },
    },
  },

  MuiList: {
    styleOverrides: {
      root: {
        padding: 12,
        display: "flex",
        gap: 12,
        flexDirection: "column",
      },
    },
  },

  MuiListItem: {
    styleOverrides: {
      root: {
        padding: "4px",
      },
    },
  },

  MuiListItemButton: {
    styleOverrides: {
      root: {
        borderRadius: 8,
        padding: "0, 18px",
        color: "#5f748d",
        "&.Mui-selected": {
          fontWeight: 500,
          color: "#2499ef",
        },
      },
    },
    defaultProps: { disableRipple: true },
  },

  MuiListItemIcon: {
    styleOverrides: {
      root: {
        color: "inherit",
        fontSize: "small",
        minWidth: 32,
      },
    },
  },

  MuiListItemText: {
    styleOverrides: {
      root: {
        fontSize: 13,
        color: "inherit",
      },
      primary: {
        fontWeight: "inherit",
      },
    },
  },
};
