import palette from "~/common/mui/theme/palette";

export const menuStyles = {
  ".MuiPaper-root": {
    marginTop: 1,
    padding: 0,
  },
  ".MuiList-root": {
    padding: 0.5,
    gap: 0.5,
  },
  ".MuiMenuItem-root": {
    fontSize: 11,
    minHeight: "24px",
    width: "100%",
    display: "flex",
    borderRadius: "8px",
    justifyContent: "space-between",
    textTransform: "uppercase",
  },
};

export const selectStyles = {
  height: 24,
  width: 136,

  padding: "0",
  borderRadius: "12px",
  background: palette.white[16],

  ".MuiInput-input": {
    color: palette.white[32], // Change the color of the selected value
    px: 1,
    py: 0.5,
  },
  ".MuiInputBase-input": {
    fontSize: 11,
    pr: "8px!important",
    color: palette.white.main, // Change the color of the selected value
    textTransform: "uppercase",

    display: "flex",
    justifyContent: "space-between",

    "&:focus": {
      background: "transparent",
    },
  },
};
