import React, { FC } from "react";
import { Box } from "@mui/material";

const WelcomeBackground: FC = () => {
  return (
    <Box
      sx={{
        filter: "blur(50px)",
        transition: "all 300ms ease-in-out",
      }}
    >
      <svg
        width="100%"
        height="100vh"
        viewBox="0 0 1440 740"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          position: "fixed",
          zIndex: -1,
        }}
      >
        <g filter="url(#filter0_f_906_12536)">
          <circle
            cx="-0.5"
            cy="664.5"
            r="200.5"
            fill="url(#paint0_radial_906_12536)"
          />
        </g>
        <g filter="url(#filter1_f_906_12536)">
          <circle
            cx="1424"
            cy="253"
            r="93"
            fill="url(#paint1_radial_906_12536)"
          />
        </g>
        <defs>
          <filter
            id="filter0_f_906_12536"
            x="-701"
            y="-36"
            width="1401"
            height="1401"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="250"
              result="effect1_foregroundBlur_906_12536"
            />
          </filter>
          <filter
            id="filter1_f_906_12536"
            x="1099.08"
            y="-71.9202"
            width="649.84"
            height="649.84"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="115.96"
              result="effect1_foregroundBlur_906_12536"
            />
          </filter>
          <radialGradient
            id="paint0_radial_906_12536"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(-77.6154 473.254) rotate(72.4635) scale(404.37)"
          >
            <stop stopColor="#E1C0FF" />
            <stop offset="0.486726" stopColor="#9282FF" />
            <stop offset="0.728982" stopColor="#9D6FFF" />
            <stop offset="1" stopColor="#EB73FF" />
          </radialGradient>
          <radialGradient
            id="paint1_radial_906_12536"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(1388.23 164.292) rotate(72.4635) scale(187.563)"
          >
            <stop stopColor="#E1C0FF" />
            <stop offset="0.486726" stopColor="#9282FF" />
            <stop offset="0.728982" stopColor="#9D6FFF" />
            <stop offset="1" stopColor="#EB73FF" />
          </radialGradient>
        </defs>
      </svg>
    </Box>
  );
};

export default WelcomeBackground;
