import React, { FC } from "react";
import {
  Box,
  Stack,
  Skeleton,
  Typography,
  Divider,
  Avatar,
} from "@mui/material";
import { Masonry } from "@mui/lab";
import palette from "~/common/mui/theme/palette";
//import DigestSkeletonBreadcrumbs from "~/components/Digest/Skeleton/Breadcumbs";
import { motion } from "framer-motion";

const DigestSkeleton: FC = () => {
  return (
    <Box>
      {/*<Box*/}
      {/*  sx={{*/}
      {/*    display: "flex",*/}
      {/*    justifyContent: "space-between",*/}
      {/*    alignItems: "center",*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <DigestSkeletonBreadcrumbs />*/}
      {/*</Box>*/}
      {/*<Box*/}
      {/*  display="flex"*/}
      {/*  alignItems="center"*/}
      {/*  justifyContent="space-between"*/}
      {/*  pb={8}*/}
      {/*>*/}
      {/*  <Box display="flex" alignItems="center" gap="50px">*/}
      {/*    <motion.div*/}
      {/*      initial={{ opacity: 0, scale: 0.9 }}*/}
      {/*      animate={{ opacity: 1, scale: 1 }}*/}
      {/*      transition={{*/}
      {/*        duration: 1,*/}
      {/*        type: "tween",*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      <Typography variant="h1">Wisefeed Daily Digest</Typography>*/}
      {/*    </motion.div>*/}
      {/*    <motion.div*/}
      {/*      initial={{ opacity: 0, x: 200 }}*/}
      {/*      animate={{ opacity: 1, x: 0 }}*/}
      {/*      transition={{*/}
      {/*        duration: 1,*/}
      {/*        type: "tween",*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      <Button*/}
      {/*        size="medium"*/}
      {/*        variant="contained"*/}
      {/*        disabled*/}
      {/*        color="secondary"*/}
      {/*        sx={{*/}
      {/*          display: "flex",*/}
      {/*          alignItems: "center",*/}
      {/*          gap: 1.3,*/}
      {/*          minWidth: 131,*/}
      {/*          borderRadius: "20px",*/}
      {/*          color: palette.white[80],*/}
      {/*          "&.Mui-disabled": {*/}
      {/*            path: { fill: "transparent", stroke: palette.white[32] },*/}
      {/*          },*/}
      {/*        }}*/}
      {/*      >*/}
      {/*        <SvgIcon viewBox="0 0 18 18" sx={{ fill: "none", fontSize: 17 }}>*/}
      {/*          <path*/}
      {/*            d="M11.6935 3.36019L14.6398 6.30647M12.9435 2.11019C13.7571 1.2966 15.0762 1.2966 15.8898 2.11019C16.7034 2.92379 16.7034 4.24288 15.8898 5.05647L4.41671 16.5296H1.50005V13.5537L12.9435 2.11019Z"*/}
      {/*            stroke={palette.white[80]}*/}
      {/*            strokeOpacity="0.64"*/}
      {/*            strokeWidth="1.66667"*/}
      {/*            strokeLinecap="round"*/}
      {/*            strokeLinejoin="round"*/}
      {/*          />*/}
      {/*        </SvgIcon>*/}
      {/*        Edit digest*/}
      {/*      </Button>*/}
      {/*    </motion.div>*/}
      {/*  </Box>*/}
      {/*</Box>*/}

      <Box
        sx={{
          position: "relative",
        }}
      >
        <Stack>
          <Box>
            <Box
              sx={{
                py: 1.5,
                m: -1,
              }}
            >
              <motion.div
                initial={{ opacity: 0, x: 200 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{
                  duration: 1,
                  type: "just",
                }}
              >
                <Divider textAlign="left">
                  <motion.div
                    variants={{
                      hidden: { opacity: 0, x: -280, y: 0 },
                      visible: { opacity: 1, x: 0, y: 0 },
                    }}
                    initial="hidden"
                    animate="visible"
                    transition={{
                      duration: 1,
                      type: "just",
                    }}
                  >
                    <Box display="flex" gap={2} alignItems="center">
                      <Avatar
                        sx={{
                          height: 32,
                          width: 32,
                          bgcolor: palette.white[4],
                          path: {
                            color: "transparent",
                          },
                        }}
                      />
                      <Typography
                        fontFamily="Poppins, Montserrat"
                        fontWeight={500}
                        fontSize={22}
                        color={palette.white.main}
                      >
                        <Skeleton width={150} height={50} />
                      </Typography>
                    </Box>
                  </motion.div>
                </Divider>
              </motion.div>
            </Box>

            <Box m={-2.5} pt={2}>
              <Masonry spacing={3} columns={3} sx={{ m: 0 }}>
                <motion.div
                  initial={{ opacity: 0, x: -40, y: -40 }}
                  animate={{ opacity: 1, x: 0, y: 0 }}
                  transition={{
                    duration: 1,
                    type: "tween",
                  }}
                  whileHover={{ scale: 1.05 }}
                >
                  <Skeleton
                    variant="rounded"
                    height={300}
                    sx={{ borderRadius: 3 }}
                  />
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, y: -40 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{
                    duration: 1,
                    type: "tween",
                  }}
                  whileHover={{ scale: 1.05 }}
                >
                  <Skeleton
                    variant="rounded"
                    height={400}
                    sx={{ borderRadius: 3 }}
                  />
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, x: 40, y: -40 }}
                  animate={{ opacity: 1, x: 0, y: 0 }}
                  transition={{
                    duration: 1,
                    type: "tween",
                  }}
                >
                  <Skeleton
                    variant="rounded"
                    height={250}
                    sx={{ borderRadius: 3 }}
                  />
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, y: 40, x: 40 }}
                  animate={{ opacity: 1, y: 0, x: 0 }}
                  transition={{
                    duration: 1,
                    type: "tween",
                  }}
                >
                  <Skeleton
                    variant="rounded"
                    height={350}
                    sx={{ borderRadius: 3 }}
                  />
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, y: 40, x: -40 }}
                  animate={{ opacity: 1, y: 0, x: 0 }}
                  transition={{
                    duration: 1,
                    type: "tween",
                  }}
                >
                  <Skeleton
                    variant="rounded"
                    height={350}
                    sx={{ borderRadius: 3 }}
                  />
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, y: 40 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{
                    duration: 1,
                    type: "tween",
                  }}
                >
                  <Skeleton
                    variant="rounded"
                    height={350}
                    sx={{ borderRadius: 3 }}
                  />
                </motion.div>
              </Masonry>
            </Box>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default DigestSkeleton;
