import dayjs, { Dayjs } from "dayjs";

export const formatScheduleToTime = (
  schedule: string | undefined
): Dayjs | null => {
  if (!schedule) return null;
  const parts = schedule.split(" ");
  const hours = parts[1];
  const minutes = parts[0];
  return dayjs(`${hours}:${minutes}`, "HH:mm");
};

export const formatTime = (t: string): any => {
  const valueToDate = dayjs(t, "HH:mm");
  return `0${valueToDate.minute()} ${
    valueToDate.hour() > 9
      ? valueToDate.hour()
      : "0" + valueToDate.hour().toString()
  } * * ? *`;
};
