import React, { FC, Fragment, ReactNode } from "react";
import { Container, Toolbar } from "@mui/material";
import Header from "~/layouts/DigestLayout/Header";

interface IDigestLayoutProps {
  children: ReactNode;
}

const DigestLayout: FC<IDigestLayoutProps> = ({ children }) => {
  return (
    <Fragment>
      <Header />
      <Toolbar />
      {/* ^ This is to prevent the content from being hidden behind the header */}
      <Container
        component="main"
        maxWidth="main"
        disableGutters
        sx={{
          paddingTop: "60px",
        }}
      >
        {children}
      </Container>
    </Fragment>
  );
};

export default DigestLayout;
