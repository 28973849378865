import React, { ReactNode, useContext, createContext } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { CognitoUser } from "@aws-amplify/auth";
import useAuth from "~/hooks/app/auth/useAuth";
import { SignInInput } from "~/types/user";

interface AuthState {
  currentUser: CognitoUser | null;
  signIn(input: SignInInput): Promise<void>;
  signOut(): Promise<void>;
  deleteUser(): Promise<void>;
}

export const AuthContext = createContext<AuthState>({
  currentUser: null,
  signIn: async () => {},
  signOut: async () => {},
  deleteUser: async () => {},
});

interface AuthProps {
  children: ReactNode;
}

export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider = ({ children }: AuthProps) => {
  const auth = useAuth();

  return (
    <AuthContext.Provider value={{ ...auth }}>{children}</AuthContext.Provider>
  );
};
