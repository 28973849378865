import { createTheme } from "@mui/material/styles";
import shadows from "./shadows";
import palette from "./palette";

import components from "./components";
import TComponents from "./components/components";

const defaultTheme = createTheme({
  typography: {
    fontFamily: `Montserrat`,
    allVariants: {
      color: "white",
    },
    h1: {
      fontSize: 52,
      fontFamily: "Playfair Display",
    },
    h2: {
      fontSize: 36,
      fontWeight: 400,
      fontFamily: "Playfair Display",
    },
    h6: {
      fontSize: 20,
      fontWeight: 400,
      color: "#D9D9D9",
      fontFamily: "Playfair Display",
    },
    body2: {
      fontSize: 12,
      fontWeight: 400,
      color: palette.white[64],
      // fontFamily: "Montserrat",
    },
  },
  breakpoints: {
    values: {
      main: 950,
      appbar: 1320,

      xs: 1,
      sm: 600,
      md: 768,
      lg: 1200,
      xl: 1450,
    },
  },
  shadows,
  palette,
  components: components as unknown as typeof TComponents,
});

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    main: true;
    appbar: true;

    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    default: true;
    auth: true;
    google: true;
    demo: true;
    tag: true;
  }
}

export default defaultTheme;
