import palette from "../../palette";

const select = {
  MuiSelect: {
    defaultProps: {
      disableUnderline: true,
    },

    styleOverrides: {
      root: {
        width: 100,
        color: palette.white.main,

        ".MuiInputBase-input": {
          color: palette.primary.main,
          fontFamily: "Poppins",
          fontSize: 16,
          fontWeight: 500,
          textAlign: "right",
        },

        ".MuiMenuItem-root": {
          width: "100%",
        },

        "&.Mui-checked": {
          backgroundColor: palette.primary.main,
        },

        "&.Mui-disabled": {
          "&.Mui-checked": {},
        },
      },
    },
  },
};

export default select;
