import React, { FC } from "react";
import { AppBar, Box, Container, Toolbar } from "@mui/material";

import { ROUTES } from "~/common/config/router/routes";
import Logo from "~/components/shared/Logo";
import { Link as RouterLink } from "react-router-dom";

const FlowLayoutHeader: FC = () => {
  return (
    <AppBar
      position="absolute"
      elevation={0}
      sx={{
        height: 73,
        background: "transparent",
      }}
    >
      <Container maxWidth="appbar" sx={{ padding: ["0 4px", "0 16px"] }}>
        <Toolbar disableGutters sx={{ height: 72 }}>
          <Box
            sx={{
              flexGrow: 1,
              gap: 4.2,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Box
              component={RouterLink}
              to={ROUTES.HOME}
              sx={{ textDecoration: "none" }}
            >
              <Logo />
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default FlowLayoutHeader;
